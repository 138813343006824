.cert-list {
    padding-left: 0;
    list-style-type: none;    

    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    @include for_breakpoint(md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    @media (min-width: 1550px) {
        grid-template-columns: repeat(3, 1fr);
    }
    &__item {

    }
}
.card-cert {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 9fr 3fr;
    gap: 15px;
    padding: 20px 15px 16px;
    height: 100%;
    background-color: var(--black);
    @include for_breakpoint(md) {
        padding: 30px 20px 28px;
    }
    @include for_breakpoint(xl) {
        grid-template-columns: 5fr 3fr;
        gap: 10px;
        padding: 40px 30px 35px;
    }
    @media (min-width: 1550px) {
        grid-template-columns: 8fr 3fr;
    }
    &:hover {
        .card-cert {
            &__title {
                color: var(--yellow)
            }
            &__icon {
                filter: grayscale(0);
            }
            &__type {
                color: #fff;
            }
        }
    }
    &__title,
    &__icon,
    &__type {
        transition: all 0.3s ease-in-out;
    }
    &__title {
        margin: 0;
        grid-area: 1 / 1 / 2 / 2;
        font-size: 16px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        @include for_breakpoint(md) {
            min-height: 40px;
        }
        @include for_breakpoint(xl) {
            min-height: 62px;
            font-size: 22px;
        }
    }
    &__preview {
        grid-area: 2 / 1 / 3 / 3;
        font-size: 12px;
        line-height: 1.5;
        @include for_breakpoint(xl) {
            grid-area: 2 / 1 / 3 / 2;
            font-size: 18px;
        }
    }
    &__icon {
        grid-area: 1 / 2 / 3 / 3;
        justify-self: end;
        align-self: start;
        filter: grayscale(1);
        max-height: 40px;
        @include for_breakpoint(xl) {
            max-height: 62px;
        }
    }
    &__type {
        grid-area: 3 / 1 / 4 / 2;
        color: #898989;
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 22px;
        @include for_breakpoint(md) {
        }
        @include for_breakpoint(xl) {
            margin-top: 20px;
            font-size: 16px;
        }
    }
    &__arrow {
        grid-area: 3 / 2 / 4 / 3;
        justify-self: end;
        align-self: end;
        width: 16px;
        height: 16px;
        @include for_breakpoint(xl) {
            width: 24px;
            height: 24px;
        }
    }
    &--white {
        color: var(--black);
        background-color: #fff;
        .card-cert__icon {
            filter: unset;
        }
        &:hover {
            .card-cert {
                &__title {
                    color: var(--yellow)
                }
                &__icon {
                    filter: unset;
                }
                &__type {
                    color: var(--black);
                }
            }
        }
        .card-cert__type {
            color: #898989;
        }
    }
}