.tariff-list {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
    @include for_breakpoint(sm) {
        margin: 0 -5px;
    }
	@include for_breakpoint(xl) {
		margin: 0 -10px;
	}
	&__item {
		padding-bottom: 10px;
		width: 100%;
		@include for_breakpoint(sm) {
            padding: 5px;
			width: 50%;
		}
		@include for_breakpoint(xl) {
			padding: 10px;
		}
	}
}
.tariff {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px 20px 22px;
	text-align: center;
	background-color: var(--black);
	@include for_breakpoint(md) {
		padding: 20px 20px 22px;
		text-align: left;
	}
	@include for_breakpoint(xl) {
		padding: 30px 32px 30px 24px;
	}
	@media(min-width:1550px) {
		padding: 40px 40px 50px 30px;
	}
	&::before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
		width: 100%;
		height: 100%;
		background-image: url("../img/spiral.png");
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0.02;
	}
	&__title {
		margin: 0;
		margin-bottom: 18px;
		color: var(--yellow);
		font-size: var(--fs-title);
		letter-spacing: 0.03;
		@include for_breakpoint(md) {
			margin-bottom: 12px;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 18px;
		}
	}
	&__info {
		margin: 0;
		margin-bottom: 24px;
		color: #898989;
		font-size: 0.75rem;
		@include for_breakpoint(md) {
			margin-bottom: 10px;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 14px;
			font-size: 1.125rem;
		}
		@media(min-width:1550px) {
			margin-bottom: 18px;
			font-size: 1.375rem;
		}
	}
	&__props {
		color: #898989;
		font-size: 0.75rem;
		@include for_breakpoint(md) {
			margin-bottom: 26px;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 50px;
			font-size: 1.125rem;
		}
		@media(min-width:1550px) {
			margin-bottom: 80px;
			font-size: 1.375rem;
		}
	}
	&__p {
		margin: 0;
	}
	&__pn {}
	&__mark {
		position: relative;
		margin: 30px 0;
		@include for_breakpoint(md) {
			position: absolute;
			top: 30px;
			right: 40px;
			margin: 0;
		}
		@include for_breakpoint(xl) {
			top: 50px;
		}
		@media(min-width:1550px) {
			top: 110px;
			right: 84px;
		}
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			width: 42px;
			height: 42px;
			border-radius: 50%;
			background-image: radial-gradient(
				rgba(144,77,160,1),
				rgba(144,77,160,1)
			);
			filter: blur(20px);
			@media(min-width:1550px) {
				width: 64px;
				height: 64px;
				filter: blur(20px);
			}
		}
	}
	&__mark-text {
		position: relative;
		z-index: 1;
		display: inline-block;
		margin: 0;
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		@include for_breakpoint(xl) {
			font-size: 2rem;
		}
		@media(min-width:1550px) {
			font-size: 3rem;
		}
	}
	&__desc {
		margin-top: auto;
		@include for_breakpoint(lg) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	&__rate {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		margin-bottom: 6px;
		@include for_breakpoint(md) {
			gap: 10px;
			margin-bottom: 10px;
			justify-content: flex-start;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 12px;
		}
	}
	&__value {
		margin: 0;
		@include for_breakpoint(xl) {
			font-size: 1.125rem;
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
		}
	}
	&__price {
		margin: 0;
		margin-bottom: 20px;
		font-size: 1.5rem;
		@include for_breakpoint(lg) {
			margin-bottom: 0;
		}
		@include for_breakpoint(xl) {
			font-size: 1.875rem;
		}
		@media(min-width:1550px) {
			font-size: 2.25rem;
		}
	}
	&__btn {
		width: 100%;
		margin: 0 auto;
		@include for_breakpoint(md) {
			margin: unset;
		}
		@include for_breakpoint(lg) {
			width: unset;
		}
		@include for_breakpoint(xl) {
			padding: 16px 72px;
		}
		@include for_breakpoint(xl) {
			padding: 20px 100px;
		}
	}
}
.ss {
    @media(min-width:1550px) {
        display: grid;
        grid-template-columns: calc(30% + 30px) calc(70% - 60px);
        column-gap: 30px;
    }
    &__title {
        @media(min-width:1550px) {
            position: relative;
            top: -14px;
            margin: 0;
            font-size: 3rem;
			& span:not([class]) {
				margin-top: 30px;
			}
        }
        @media (min-width: 1720px) {
            top: -24px;
            font-size: 4rem;
        }
    }
    &__content {
        font-size: 0.75rem;
        line-height: 1.5;
		margin-bottom: 24px;
		@include for_breakpoint(md) {
			// margin-bottom: 52px;
		}
		@include for_breakpoint(lg) {
			// margin-bottom: 34px;
		}
		@include for_breakpoint(xl) {
			// margin-bottom: 58px;
		}
		@media(min-width:1550px) {
			// margin-bottom: 74px;
		}
        @include for_breakpoint(md) {
            display: grid;
            grid-template-columns: calc((2/3) * 100% + 50px) calc((1/3) * 100% - 70px);
			column-gap: 20px;
            font-size: var(--fs);
        }
		@include for_breakpoint(xl) {
			grid-template-columns: calc((2/3) * 100% - 30px) calc((1/3) * 100% - 40px);
			column-gap: 70px;
		}
		@media(min-width:1550px) {
			grid-template-columns: calc((2/3) * 100% - 60px) calc((1/3) * 100% + 90px);
			column-gap: 30px;
		}
    }
    &__text-intro {
        margin: 0;
        margin-bottom: 20px;
        @include for_breakpoint(md) {
			margin-top: 8px;
            margin-bottom: 30px;
        }
        @include for_breakpoint(xl) {
            margin-bottom: 40px;
        }
        @media(min-width:1550px) {
            margin-top: 0;
        }
		& br {
			display: none;
			@media(min-width:1550px) {
				display: block;
			}
		}
    }
    &__img-wrap {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 36px;
        @include for_breakpoint(md) {
			margin-top: -8px;
			margin-bottom: 0;
            grid-row: 1/3;
            grid-column: 2/3;
            flex-direction: column;
            margin-bottom: 0;
            & > img {
                width: 100%;
				max-width: 200px;
				@include for_breakpoint(lg) {
					max-width: 235px;
				}
				@media(min-width:1550px) {
					max-width: 300px;
				}
            }
        }
        @include for_breakpoint(lg) {
            gap: 36px;
        }
        @include for_breakpoint(xl) {
			margin-top: -42px;
            gap: 42px;
        }
		@media(min-width:1550px) {
			margin-top: 0;
		}
    }
    &__img-bx {
        display: flex;
        align-items: center;
        gap: 10px;
        @include for_breakpoint(lg) {
            gap: 30px;
        }
        & img {
            width: calc(50% - 5px);
			max-width: 75px;
			@include for_breakpoint(xl) {
				max-width: unset;
			}
        }
    }
    &__text-end {
        margin: 0;
    }
}
.cms-list {
    padding: 16px 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    height: 64px;
	@media(min-width:1550px) {
		height: 100px;
	}
    &__item {
    }
}
.cms-img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.achiev-list {
    padding: 0;
    list-style: none;
    display: flex;
    @include for_breakpoint(md) {
        gap: 20px;
    }
    &__item {
		width: calc(100% / 3);
        align-self: stretch;
    }
}
.s-workplan {
	@include for_breakpoint(md) {
		display: grid;
		grid-template-columns: calc((1/2)*100% - 40px) calc((1/2)*100%);
		gap: 40px;
	}
	@include for_breakpoint(xl) {
		grid-template-columns: calc((1/3)*100% - 40px) calc((2/3)*100% - 30px);
		gap: 70px;
	}
	@media(min-width:1550px) {
		grid-template-columns: calc((1/3)*100%) calc((2/3)*100% - 54px);
		gap: 54px;
	}
	&__title {
		@include for_breakpoint(md) {
			margin: 0;
			margin-top: 12px;
		}
		@include for_breakpoint(xl) {
			span:not([class]) {
				margin-top: 30px;
			}
		}
	}
	&__content {

	}
}
.ss + .content {
	details[id]:not([class=""]) {
		--color-underline: #898989;
		display: inline;
		summary {
			display: inline;
			list-style-type: none;
			color: #898989;
			cursor: pointer;
			background-image: linear-gradient(0deg, transparent 0, transparent 2px, var(--color-underline) 2px, var(--color-underline) 3px, transparent 3px, transparent 100%);
			transition: all 0.3 ease-in-out;
			@media (min-width: 1550px) {
				.link {
					background-image: linear-gradient(0deg, transparent 0, transparent 0.07em, var(--color-underline) 0.07em, var(--color-underline) calc(0.07em + 1px), transparent calc(0.07em + 1px), transparent 100%);
				}
			}
			&:hover {
				--color-underline: var(--gray);
			}
		}
		& + div[aria-details] {
			height: 100%;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.5s ease;
		}
		& + div[aria-details="tech-support"] {
			margin-bottom: 22px;
			h3 {
				margin-bottom: 0.25em;
			}
			@media (min-width:1550px) {
				h3 {
					font-size: 40px;
				}
			}
		}
		& + div[aria-details="tech-support"] > *:first-child {
			margin-top: 0.75em;
		}
		&[open] + div[aria-details="tech-support"] {
			max-height: 1330px;
			@include for_breakpoint(md) {
				max-height: 910px;
			}
			@include for_breakpoint(xl) {
				max-height: 1150px;
			}
			@media (min-width:1550px) {
				max-height: 1230px;
			}
		}
	}
}