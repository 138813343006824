.blog-list {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;

    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    @include for_breakpoint(md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include for_breakpoint(xl) {
        gap: 20px;
    }
    &__item {
        &--lead {
            margin-bottom: 10px;
            @include for_breakpoint(md) {
                grid-column: 1 / -1;
                margin-bottom: 30px;
            }
            @include for_breakpoint(xl) {
                margin-bottom: 50px;
            }
            @media (min-width: 1550px) {
                margin-bottom: 60px;
            }
        }
    }
}
.card-blog {
    position: relative;
    height: 100%;
    padding: 18px 15px 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include for_breakpoint(md) {
        padding: 26px 20px;
    }
    @include for_breakpoint(xl) {
        padding: 34px 32px 40px;
    }
    @media (min-width: 1550px) {
        padding: 40px 30px 50px;
    }
    &__header {
        margin-bottom: 10px;
        @include for_breakpoint(md) {
            margin-bottom: 20px;
        }
        @include for_breakpoint(xl) {
            margin-bottom: 26px;
        }
        @media (min-width: 1550px) {
            margin-bottom: 24px;
        }
    }
    &__title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.03em;
        @include for_breakpoint(md) {
            font-size: 16px;
        }
        @include for_breakpoint(xl) {
            font-size: 24px;
        }
        @media (min-width: 1550px) {
            font-size: 30px;
            width: calc(100% - 100px);
        }
        @include for_breakpoint(xl-max) {
            &:not(.card-blog--lead .card-blog__title) a {
                color: var(--yellow);
                &:hover, &:focus {
                    color: #898989;
                }
                &:active {
                    color: var(--yellow);
                }
            }
        }
    }
    &__body {
        margin-top: auto;
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 1.5;
        color: #898989;
        @include for_breakpoint(md) {
            margin-bottom: 18px;
        }
        @include for_breakpoint(xl) {
            margin-bottom: 32px;
            font-size: 18px;
        }
        @media (min-width: 1550px) {
            margin-bottom: 46px;
            width: calc(100% - 100px);
            font-size: 22px;
        }
    }
    &__footer {
        @include for_breakpoint(xl) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;
            align-items: center;
            gap: 90px;
        }
    }
    &__date {
        color: #898989;
        font-size: 12px;
        line-height: 1.5;
        @include for_breakpoint(xl) {
            font-size: 14px;
        }
        @media (min-width: 1550px) {
            position: absolute;
            top: 40px;
            right: 30px;
            transform-origin: right center;
            transform: 
                rotate(-90deg)
                translate(0, -50%);
            font-size: 16px;
        }
    }
    &__btn {
        display: none;
        @include for_breakpoint(xl) {
            display: block;
            min-width: 284px;
            font-size: 22px;
            letter-spacing: 0.12em;
        }
        @include for_breakpoint(xl) {
            min-width: 320px;
        }
    }
    &--lead {
        padding: 26px 15px;
        @include for_breakpoint(md) {
            padding: 24px 20px 38px;
        }
        @include for_breakpoint(xl) {
            padding: 40px 30px 50px;
        }
        @media (min-width: 1550px) {
            padding: 40px 30px 54px;
        }
        & .card-blog {
            &__header {
                margin-bottom: 52px;
                @include for_breakpoint(md) {
                    margin-bottom: 58px;
                }
                @include for_breakpoint(xl) {
                    margin-bottom: 90px;
                }
                @include for_breakpoint(xl) {
                    margin-bottom: 80px;
                }
            }
            &__title {
                margin-top: 38px;
                font-size: 20px;
                @include for_breakpoint(md) {
                    margin-top: 0;
                    font-size: 24px;
                }
                @include for_breakpoint(xl) {
                    font-size: 30px;
                }
            }
            &__body {
                color: #fff;
                font-size: 14px;
                @include for_breakpoint(md) {
                    margin-bottom: 32px;
                    font-size: 18px;
                }
                @include for_breakpoint(xl) {
                    margin-bottom: 28px;
                    font-size: 22px;
                }
            }
            &__date {
                position: absolute;
                top: 26px;
                left: 15px;
                transform: none;
                padding: 4px 6px; 
                color: #fff;
                background-color: rgba(26,26,26,0.5);
                @include for_breakpoint(md) {
                    left: unset;
                    top: 30px;
                    right: 20px;
                    padding: 6px 12px; 
                    font-size: 14px;
                }
                @include for_breakpoint(xl) {
                    top: 50px;
                    right: 30px;
                    padding: 10px 16px; 
                    font-size: 16px;
                }
            }
            &__btn {
                display: block;
                @include for_breakpoint(xl) {
                    min-width: 284px;
                    font-size: 22px;
                    letter-spacing: 0.12em;
                }
                @media (min-width: 1550px) {
                    min-width: 320px;
                    font-size: 22px;
                    letter-spacing: 0.12em;
                }
            }
            @include for_breakpoint(md) {
                &__title, &__body {
                    width: 60%;
                }
            }
            @include for_breakpoint(xl) {
                &__title, &__body {
                    width: 50%;
                }
            }
            @media (min-width: 1550px) {
                &__title, &__body {
                    width: 40%;
                }
            }
        }
    }
}
.post-header {
    display: flex;
    flex-flow: column-reverse;
    padding-bottom: 20px;
    @include for_breakpoint(md) {
        flex-flow: row;
        align-items: stretch;
        gap: 20px;
        padding-bottom: 30px;
    }
    @include for_breakpoint(xl) {
        padding-bottom: 70px;
    }
    @media (min-width: 1550px) {
        padding-bottom: 60px;
    }
    &__info {
        flex: 1;
        display: flex;
        flex-flow: column;
    }
    &__title {
        font-size: var(--fs-title);
    }
    &__author-wrap {
        display: flex;
        flex-flow: column;
        gap: 8px;
        color: #898989;
        font-size: 12px;
        @include for_breakpoint(md) {
            flex-flow: row;
            gap: 20px;
        }
        @include for_breakpoint(xl) {
            gap: 40px;
            font-size: 16px;
            margin-bottom: 16px;
        }
    }
    &__author {
        display: inline-flex;
        gap: 8px;
    }
    &__readtime {
        margin-top: 18px;
        @include for_breakpoint(md) {
            margin-top: auto;
        }
    }
    &__img-wrap {
        flex: 1;
        img {
            width: 100%;
            height: 100%;
            max-height: 390px;
            object-fit: cover;
        }
    }
}
.readtime {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    @include for_breakpoint(md) {
        gap: 10px;
        font-size: 16px;
    }
    @include for_breakpoint(xl) {
        gap: 15px;
        font-size: 24px;
    }
    &__clock {
        width: 12px;
        height: 12px;
        @include for_breakpoint(md) {
            width: 20px;
            height: 20px;
        }
        @include for_breakpoint(xl) {
            width: 34px;
            height: 34px;
        }
    }
    &__tilda {
        max-width: 8px;
        @include for_breakpoint(md) {
            max-width: 10px;
        }
        @include for_breakpoint(xl) {
            max-width: 16px;
        }
    }
    &__text {
        letter-spacing: 0.03em;
    }
}