.site-body {
	--header-hight: 45px;
	padding-top: calc(var(--header-hight) + 9px);
	@media (min-width: 710px) {
		--header-hight: 61px;
		padding-top: calc(var(--header-hight) + 26px);
	}
	@include for_breakpoint(lg) {
		--header-hight: 65px;
	}
	@include for_breakpoint(xl) {
		--header-hight: 80px;
		padding-top: calc(var(--header-hight) + 40px);
	}
	@media(min-width:1550px) {
		padding-top: calc(var(--header-hight) + 68px);
	}
	&__site-header {
		position: fixed;
		top: 0;
		z-index: 1001;
	}
}
/* шапка */
.site-header {
	// border-bottom: 1px solid #4A4A4A;
	background-color: var(--gray);
	width: 100%;
	--borderWidth: 100%;
	&::after {
		content: "";
		position: absolute;
		display: block;
		width: var(--borderWidth);
		height: 1px;
		background-color: #4A4A4A;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0);
	}
	&__btn-menu {
		position: relative;
		left: -6px;
	}
	&__inner {
		position: relative;
		z-index: 100;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.375rem 0;
		gap: 0.75rem;
		@media (min-width: 710px) {
			padding: 0.875rem 0;
		}
		@include for_breakpoint(xl) {
			gap: 2rem;
			padding: 0.75rem 0;
		}
		@media(min-width:1550px) {
			gap: 50px;
		}
	}
	&__nav {
		position: fixed;
		top: var(--header-hight);
		left: -100vw;
		width: 100vw;
		height: 100vh;
		background-color: var(--black);
		transition: all 0.2s ease-in-out;
		@include for_breakpoint(md) {
			width: 320px;
			transform: none;
			& > .box {
				padding-right: 0;
			}
		}
		@include for_breakpoint(xl) {
			width: 480px;
			transform: none;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: 100%;
				display: block;
				width: 9999px;
				height: 100%;
				background-color: var(--black);
			}
		}
		&.active {
			@include for_breakpoint(md-max) {
				background-color: var(--black);
				&.open {
					background-color: var(--gray);
					&::before {
						content: "";
						position: absolute;
						display: block;
						width: 100%;
						height: 8px;
						background-color: var(--black);
					}
					.menu__item {
						border-color: transparent;
					}
				}
			}
		}
		@media (min-width: 1800px) {
			width: 320px;
		}
	}
	&__logo {
		margin-right: auto;
		@include for_breakpoint(md) {
			margin-right: unset;
		}
	}
}
.btn-menu {
	display: inline-flex;
	flex-direction: column;
	gap: 6px;
	padding: 6px;
	cursor: pointer;
	@include for_breakpoint(lg) {
		gap: 9px;
	}
	& span {
		display: block;
		width: 20px;
		height: 2px;
		background-color: #fff;
		transition: all 0.2s ease-in-out;
		@include for_breakpoint(lg) {
			width: 30px;
		}
	}
	&:hover {
		& span {
			background-color: var(--yellow);
		}
	}
}
#btn-menu {
	&:checked + .btn-menu {
		& span {
			background-color: var(--yellow);
		}
		& + .site-header__nav {
			left: 0;
			@include for_breakpoint(md) {
				left: 0;
			}
			@media (min-width: 1600px) {
				left: 50%;
				transform: translate(-800px, 0);
			}
			& .menu {
				opacity: 1;
			}
		}
	}
	&:not(:checked):not(:hover) + .btn-menu {
		& span {
			background-color: #fff;
		}
		& + .site-header__nav .menu {
			transition-delay: 0s;
		}
	}
}
.menu-wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100vh - var(--header-hight));
	min-height: calc(100dvh - var(--header-hight));
	padding: 0 0 30px;
}
.menu {
	position: relative;
	padding: 0;
	padding-top: 8px;
	margin: 0;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	height: 100%;
	&::before {
		content: "";
		position: absolute;
		display: block;
		width: 100vw;
		height: 1px;
		background-color: #4A4A4A;
		top: -1px;
		left: 0;
	}
	&.active .menu__item:not(.active) {
		@include for_breakpoint(md-max) {
			display: none;
		}
	}
	&__item {
		border-bottom: 1px solid #323232;
		& > a {
			display: block;
			width: 100%;
			padding: 15px 0;
			text-decoration: none;
			text-transform: uppercase;
			transition: color 0.2s ease-in-out;
			@include for_breakpoint(xl) {
				font-size: 1.25rem;
			}
			&:hover {
				color: var(--yellow);
			}
		}
		&-parent {
			& > a {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@include for_breakpoint(md) {
					padding-right: 20px;
				}
				&::after {
					content: "";
					display: block;
					width: 8px;
					height: 8px;
					background-image: url("../img/chevron.svg");
					background-repeat: no-repeat;
					background-position: 50% 50%;
					background-size: cover;
					@media (min-width: 1600px) {
						width: 12px;
						height: 12px;
					}
				}
				&:hover::after {
					background-image: url("../img/chevron-yellow.svg");
				}
			}
		}
		&.active {
			& > a {
				color: var(--yellow);
				&::after {
					background-image: url("../img/chevron-yellow.svg");
				}
			}
			@include for_breakpoint(md-max) {
				& > a {
					display: none;
				}
				& > .menu__submenu {
					display: flex;
					& .menu__subitem-back {
						position: relative;
						display: flex;
						align-items: center;
						gap: 16px;
						text-transform: uppercase;
						&::after {
							content: "";
							position: absolute;
							z-index: 0;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							display: block;
							width: 9999px;
							height: 100%;
							background-color: var(--black);
						}
						&::before {
							content: "";
							position: relative;
							z-index: 2;
							top: -2px;
							display: block;
							width: 8px;
							height: 8px;
							background-image: url("../img/chevron.svg");
							background-repeat: no-repeat;
							background-position: 50% 50%;
							background-size: cover;
							transform: rotate(180deg);
						}
						& > a {
							position: relative;
							z-index: 2;
						}
					}
					& .menu__subitem {
						& > a {
							display: block;
						}
					}
				}
			}
			@include for_breakpoint(md) {
				position: relative;
				& > .menu__submenu {
					position: absolute;
					top: 0;
					left: 100%;
					width: calc(100% + var(--gutter-x));
					padding: 0 30px;
					display: flex;
					&::before {
						content: "";
						position: absolute;
						z-index: -1;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						display: block;
						width: 100%;
						height: 9999px;
						background-color: var(--gray);
					}
					& .menu__subitem-back {
						display: none;
					}
					& .menu__subitem {
						& > a {
							display: block;
						}
					}
				}
			}
			@media (min-width: 1600px) {
				& > .menu__submenu {
					left: 100%;
				}
			}
		}
	}
	&__submenu {
		display: none;
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		background-color: var(--gray);
	}
	&__subitem {
		& > a {
			display: none;
			width: 100%;
			padding: 15px 0;
			text-decoration: none;
			@include for_breakpoint(xl) {
				font-size: 1.25rem;
			}
		}
	}
}
.menu-add {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	gap: 12px;
}
.logo {
	display: inline-flex;
	align-items: center;
	svg {
		width: 128px;
		height: auto;
		@include for_breakpoint(xl) {
			width: 220px;
		}
	}
}
.submenu-list {
	padding: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
	gap: 1.5rem;
	margin: 0;
	&__text {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		border: 0;
		padding: 0;
		white-space: nowrap;
		clip-path: inset(100%);
		clip: rect(0 0 0 0);
		overflow: hidden;
	}
	&__btn,
	&__btn-copy {
		display: none !important;
	}
	@media (min-width: 710px) {
		&__item {
			position: relative;
			&-mail {
				.submenu-list__svg {
					display: none;
				}
				.submenu-list__text {
					all: revert;
					display: block;
					line-height: 1.5;
					font-size: 0.75rem;
					font-weight: 300;
				}
				.submenu-list__btn-copy {
					display: block;
					padding: 0;
					border: none;
					color: inherit;
					font-size: 0.75rem;
					text-decoration: underline;
					font-weight: 300;
					background: none;
					&:hover {
						text-decoration: none;
					}
					&:active {
						color: var(--yellow);
					}
				}
			}
			&-tg {
				transition: color 0.2s ease-in-out;
				&:hover {
					path {
						fill: #fff;
					}
				}
				&:active {
					color: var(--yellow);
					path {
						fill: var(--yellow);
					}
				}
			}
			&-tel {
				display: inline-flex;
				align-items: center;
				gap: 1rem;
				.submenu-list__svg {
					display: none;
				}
				.submenu-list__text {
					all: revert;
					display: block;
					letter-spacing: 0.03rem;
				}
				.submenu-list__btn {
					display: block;
				}
			}
		}
	}
	@include for_breakpoint(md) {
		width: 100%;
		&__item {
			&-mail {
				margin-left: 1.4rem;
				margin-right: auto;
			}
			&-tg {
				margin: 0 auto;
				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
		&__btn,
		&__btn-copy {
			display: block !important;
		}
	}
	@include for_breakpoint(lg) {
		&__item {
			&-tg {
				.submenu-list__link {
					display: inline-flex;
					flex-direction: row-reverse;
					align-items: center;
					gap: 1rem;
				}
				.submenu-list__text {
					all: revert;
					display: block;
					text-transform: uppercase;
					letter-spacing: 0.03rem;
				}
			}
		}
	}
	@include for_breakpoint(xl) {
		&__item {
			&-mail {
				.submenu-list__text {
					font-size: 1rem;
				}
				.submenu-list__btn-copy {
					font-size: 0.875rem;
				}
			}
			&-tg {}
			&-tel {
				gap: 1.25rem;
				.submenu-list__btn {
					padding: 0.75rem 1.25rem;
					font-size: 0.875rem;
				}
			}
		}
	}
	@media (min-width: 1300px) {
		&__item {
			&-mail {
				margin-left: 42px;
				margin-right: unset;
			}
			&-tg {}
			&-tel {
				gap: 26px;
				.submenu-list__text {
					font-size: 1.25rem;
					letter-spacing: 0.1em;
				}
				.submenu-list__btn {
					padding: 12px 20px;
					font-size: 0.875rem;
				}
			}
		}
	}
	&__svg {
		path {
			fill: #787878;
			transition: all 0.2s ease-in-out;
		}
		&:hover,
		&:focus {
			& path {
				fill: #fff;
			}
		}
		&:active {
			& path {
				fill: var(--yellow);
			}
		}
	}
}
/* --шапка */
/* крошки */
.breadcrumbs-list {
	display: none;
	@include for_breakpoint(xl) {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		padding: 0;
		margin: 0;
		margin-top: -8px;
		list-style: none;
		&__item {
			position: relative;
			&:not(:first-of-type)::before {
				content: "/";
				position: absolute;
				top: 50%;
				left: -10px;
				transform: translate(0, -50%);
			}
			&:last-of-type {
				& a {
					color: #898989;
					pointer-events: none;
				}
			}
		}
	}
	@media(min-width:1550px) {
		margin-top: -24px;
		margin-bottom: 30px;
	}
}
/* --крошки */
/* футер */
.text-gradient-wrap {
	position: relative;
}
.text-gradient {
	position: relative;
	z-index: 1;
	color: var(--gray);
	// color: transparent;
	-webkit-text-stroke: 1px #4A4A4A;
	/* Толщина и цвет обводки */
	text-stroke: 1px #4A4A4A;
	/* Толщина и цвет обводки (стандартное свойство) */
	font-size: 3.4rem;
	line-height: 1;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	// background: linear-gradient(90deg,
	//         rgba(255, 205, 28, 1) 0%,
	//         rgba(255, 181, 0, 1) 50%,
	//         rgba(235, 129, 117, 1) 75%,
	//         rgba(144, 77, 160, 1) 100%);
	// -webkit-background-clip: text;
	// -webkit-text-fill-color: transparent;
	margin: 52px 0 24px;
	text-align: left;
	@include for_breakpoint(sm) {
		br {
			display: none;
		}
		margin: 80px 0 30px;
		font-size: 8.5vw;
		line-height: 7vw;
		text-align: center;
	}
	@include for_breakpoint(xl) {
		margin: 100px 0 50px;
	}
	@media(min-width:1550px) {
		margin: 235px 0 80px;
	}
	@media (min-width: 1800px) {
		margin: 230px 0 68px;
		font-size: 165px;
	}
}
.site-footer {
	&__nav {
		margin-bottom: 30px;
		@include for_breakpoint(md) {
			margin-bottom: 40px;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 30px;
		}
		@media(min-width:1550px) {
			margin-bottom: 40px;
		}
	}
	&__copyright {
		margin-bottom: 20px;
		@include for_breakpoint(md) {
			margin-bottom: 30px;
		}
		@media(min-width:1550px) {
			margin-bottom: 48px;
		}
	}
	&__text-gradient {}
}
.f-contacts {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	gap: 30px;
	@include for_breakpoint(md) {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
	}
	@include for_breakpoint(xl) {
		flex-direction: column;
		align-items: start;
		gap: 30px;
	}
	@media(min-width:1550px) {
		gap: 44px;
	}
	&__item {
		@include for_breakpoint(xl) {
			svg {
				width: 140px;
				height: 24px;
			}
		}
		@media(min-width:1550px) {
			svg {
				width: 160px;
				height: 28px;
			}
		}
		&-tel {
			display: flex;
			flex-direction: column;
			gap: 15px;
			@include for_breakpoint(md) {
				gap: 10px;
			}
			@include for_breakpoint(xl) {
				font-size: 1.125rem;
			}
			@media(min-width:1550px) {
				gap: 16px;
				font-size: 1.375rem;
			}
		}
		&-address {
			display: flex;
			flex-direction: column;
			gap: 20px;
			a {
				text-decoration: underline;
			}
			@include for_breakpoint(md) {
				gap: 16px;
			}
			@include for_breakpoint(xl) {
				gap: 30px;
				a {
					font-size: 1.125rem;
				}
			}
			@media(min-width:1550px) {
				gap: 44px;
				a {
					font-size: 1.375rem;
				}
			}
		}
	}
	&__address {
		font-size: 0.75rem;
		@include for_breakpoint(md) {
			br {
				display: none;
			}
		}
		@include for_breakpoint(xl) {
			font-size: 0.875rem;
			br {
				display: block;
			}
		}
		@include for_breakpoint(xl) {
			font-size: 1rem;
		}
	}
}
.menu-footer {
	padding: 0;
	margin: 0;
	list-style-type: none;
	&__f-contacts {
		margin-bottom: 30px;
		@include for_breakpoint(md) {
			width: 100%;
		}
		@include for_breakpoint(xl) {
			width: auto;
			margin-bottom: 0;
		}
		& > span {
			display: none;
			@include for_breakpoint(xl) {
				display: block;
				margin-bottom: 24px;
				color: #555555;
				font-size: 1.25rem;
				font-weight: 400;
				letter-spacing: 0.05rem;
				text-transform: uppercase;
			}
			@media(min-width:1550px) {
				margin-bottom: 30px;
				font-size: 1.375rem;
			}
		}
	}
	@include for_breakpoint(md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	@media (min-width: 1800px) {
		justify-content: unset;
		gap: 192px;
	}
	&__item {
		@include for_breakpoint(md) {
			&-rel {
				position: relative;
				left: -22px;
			}
		}
		@include for_breakpoint(xl) {
			&-rel {
				position: static;
				left: unset;
			}
		}
		&:not(.menu-footer__f-contacts) {
			position: relative;
			display: block;
			padding: 20px 10px;
			border-top: 1px solid #4A4A4A;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			@include for_breakpoint(md) {
				padding: 0;
				border: none;
				// pointer-events: none;
				cursor: default;
			}
			&:last-child {
				border-bottom: 1px solid #4A4A4A;
				@include for_breakpoint(md) {
					border: none;
				}
			}
			& > span {
				font-weight: normal;
				letter-spacing: 0.05rem;
				text-transform: uppercase;
				@include for_breakpoint(md) {
					color: #555555;
				}
				@include for_breakpoint(xl) {
					font-size: 1.25rem;
				}
				@include for_breakpoint(xl) {
					font-size: 1.375rem;
				}
			}
			&:hover > span {
				color: var(--yellow);
				@include for_breakpoint(md) {
					color: #555555;
				}
			}
			&::after {
				content: "+";
				position: absolute;
				top: 10px;
				right: 10px;
				display: inline-block;
				font-size: 36px;
				font-weight: 300;
				transition: transform 0.2s ease-in-out;
				@include for_breakpoint(md) {
					display: none;
				}
			}
			@include for_breakpoint(md-max) {
				&.active {
					color: var(--yellow);
					padding-bottom: 30px;
					&::after {
						transform: rotate(45deg);
						color: var(--yellow);
					}
					& > .menu-footer__submenu {
						display: block;
						color: #fff;
					}
				}
			}
		}
	}
	&__submenu {
		display: none;
		padding: 0;
		margin: 0;
		list-style-type: none;
		@include for_breakpoint(md) {
			display: block;
		}
		&-item {
			margin-top: 18.5px;
			&:first-child {
				margin-top: 24px;
				@include for_breakpoint(xl) {
					margin-top: 24px;
				}
				@media(min-width:1550px) {
					margin-top: 28px;
				}
			}
			@include for_breakpoint(xl) {
				margin-top: 20px;
				font-size: 1.125rem;
			}
			@media(min-width:1550px) {
				margin-top: 28px;
				font-size: 1.375em;
			}
		}
	}
}
.copyright {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 16px;
	@include for_breakpoint(md) {
		gap: 40px;
	}
	@include for_breakpoint(xl) {
		align-items: flex-end;
	}
	&__desc {
		display: flex;
		flex-direction: column;
		gap: 16px;
		font-size: 0.75rem;
		color: #696969;
		@include for_breakpoint(md) {
			width: 100%;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
		}
		@include for_breakpoint(xl) {
			font-size: 0.875rem;
		}
		@media(min-width:1550px) {
			font-size: 1rem;
		}
		p {
			margin: 0;
		}
	}
	&__copy {
		display: flex;
		flex-direction: column;
		gap: 16px;
		@include for_breakpoint(xl) {
			flex: 4;
			flex-direction: row;
			justify-content: space-between;
			& p:last-child {
				margin: 0 auto;
			}
		}
	}
	&__policy {
		@include for_breakpoint(xl) {
			flex: 1;
			margin-left: auto;
		}
		a {
			text-decoration: underline;
		}
	}
	&__btn {}
}
/* --футер */
/* модалка */
.modal {
	position: fixed;
	display: none;
	width: 100%;
	z-index: 1000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.modal-content {
	position: relative;
	padding: 20px 15px;
	margin: 0 auto;
	max-width: 300px;
	@include for_breakpoint(sm) {
		max-width: 400px;
	}
	@include for_breakpoint(lg) {
		max-width: 580px;
		padding: 50px 30px;
	}
	&__close {
		position: absolute;
		right: 15px;
		padding: 6px;
		border: none;
		font-size: 1.625rem;
		line-height: 1.625rem;
		font-weight: 300;
		background: none;
		cursor: pointer;
		@include for_breakpoint(sm) {
			top: 12px;
			right: 12px;
			font-size: 2.5rem;
		}
		@include for_breakpoint(lg) {
			top: 16px;
			right: 16px;
			font-size: 3rem;
		}
	}
	&--bg-yellow {
		background-color: var(--yellow);
		color: var(--black);
		& input {
			--input-color: var(--black);
			--input-ph-color: rgba(26, 26, 26, 0.3);
		}
	}
}
/* --модалка */
/* форма */
.form {
	&__header {
		margin-bottom: 60px;
	}
	&__body {
		margin-bottom: 50px;
	}
	&__footer {}
	&__title {
		margin: 0;
		font-size: 1.625rem;
		font-weight: 500;
		@include for_breakpoint(lg) {
			font-size: 3rem;
		}
	}
	&__field-wrap {
		margin-top: 34px;
	}
	&__btn {
		margin: 0 auto 18px 0;
	}
	&__notice {
		font-size: 14px;
		margin: 0;
	}
	&__policy {
		& a {
			--color-underline: var(--black);
		}
	}
	.starrequired {
		color: red;
	}
	/**/
	&__note {
		margin-top: 30px;
		margin-bottom: 22px;
		color: #898989 !important;
		font-size: 0.75rem;
		@include for_breakpoint(xl) {
			font-size: 0.875rem;
		}
		@include for_breakpoint(xl) {
			font-size: 1rem;
		}
		a {
			// transition: color 0.2s ease-in-out;
			background-image: linear-gradient(0deg, #898989 0, #898989 1px, transparent 1px, transparent 100%);
			&:hover {
				color: var(--yellow);
				background-image: none;
			}
		}
	}
	&__btn {
		@include for_breakpoint(xl) {
			margin-top: 24px;
		}
		@media(min-width:1550px) {
			margin-top: 34px;
		}
	}
}
/* --форма */
.js-copy-wrap .vnotify-container {
	position: absolute !important;
}
.js-copy-wrap .vnotify-container.vn-center {
	top: calc(100% + var(--header-hight) - 10px) !important;
}
.vnotify-container {
	z-index: 100;
}
.vnotify-item.vnotify-info {
	background: var(--black);
	color: #fff;
	opacity: 1;
}
.vnotify-container.vn-top-right {
	top: calc(var(--header-hight) + 10px) !important;
}
.vnotify-container.vn-top-left {
	top: calc(var(--header-hight) + 10px) !important;
}
/* постраничная навигация */
.pagination {
	&-wrap {
		display: grid;
		grid-template-columns: auto;
		gap: 10px;
		@include for_breakpoint(xl) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 1550px) {
			gap: 20px;
		}
		&__pagination {
			margin-top: 40px;
			@include for_breakpoint(xl) {
				margin-top: 60px;
			}
			@media (min-width: 1550px) {
				margin-top: 80px;
			}
		}
	}
	padding-left: 0;
	margin-top: 0;
	list-style-type: none;
	
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 14px;
	letter-spacing: 0.03em;
	@include for_breakpoint(md) {
		gap: 20px;
		font-size: 16px;
	}
	@include for_breakpoint(xl) {
		gap: 10px;
		font-size: 24px;
	}
	&__item {
		min-width: 36px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--black);
		@include for_breakpoint(md) {
			min-width: 42px;
			height: 42px;
		}
		@include for_breakpoint(xl) {
			min-width: 52px;
			height: 52px;
		}
		a {
			padding: 12px 9px;
			@include for_breakpoint(md) {
				padding: 16px 12px;
			}
			@include for_breakpoint(xl) {
				padding: 18px 10px;
			}
		}
		svg {
			@include for_breakpoint(md) {
				width: 16px;
				height: 16px;
			}
			@include for_breakpoint(xl) {
				width: 22px;
				height: 22px;
			}
		}
		&:hover svg path {
			fill: var(--yellow);
		}
		&.active {
			color: #000;
			background-color: var(--yellow);
			pointer-events: none;
		}
		&--prev {
			margin-right: auto;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&--next {
			margin-left: auto;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&--sep {
			position: relative;
			min-width: unset;
			width: 4px;
			height: 4px;
			padding: 0;
			background-color: var(--black);
			@include for_breakpoint(md) {
				margin: 0 20px;
				&::before, &::after {
					content: "";
					position: absolute;
					top: 50%;
					display: block;
					width: 14px;
					height: 14px;
					background-color: var(--black);
				}
				&, &::before, &::after {
					width: 4px;
					height: 4px;
				}
				&::before {
					right: calc(100% + 5px);
					transform: translate(0, -50%);
				}
				&::after {
					left: calc(100% + 5px);
					transform: translate(0, -50%);
				}
			}
			@include for_breakpoint(xl) {
				&, &::before, &::after {
					width: 6px;
					height: 6px;
				}
				&::before {
					right: calc(100% + 10px);
				}
				&::after {
					left: calc(100% + 10px);
				}
			}
		}
	}
}