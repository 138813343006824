:root {
	--yellow: #FFCF1C;
	--black: #1A1A1A;
	--gray: #2D2D2D;
	--gutter-x: 1.25rem;
	--fs: 1rem; // 16
	--fs-title: 1.75rem; // 28
	--fs-accent: 1rem; // 16
	@media (min-width: 370px) {
		--fs-title: 30px; // 
	}
	@include for_breakpoint(md) {
		--gutter-x: 2.5rem;
	}
	@include for_breakpoint(lg) {
		--fs-title: 2rem; // 32
		--fs-accent: 1.125rem; // 18
	}
	@include for_breakpoint(xl) {
		--gutter-x: 10rem;
		--fs: 1.125rem; // 18
		--fs-title: 3rem; // 48
		--fs-accent: 1.5rem;
	}
	@media(min-width:1550px) {
		--fs: 1.375rem; // 22
		--fs-title: 62px; //3.75rem; // 64
		--fs-accent: 1.875rem;
	}
}
html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: 'Stem';
	font-weight: 300;
	background-color: var(--gray);
	color: #fff;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
html,
body {
	padding: 0;
	margin: 0;
	overflow-x: hidden;
	scroll-behavior: smooth;
	-webkit-tap-highlight-color: transparent;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
}
a {
	color: inherit;
	text-decoration: none;
	&:focus {
		outline: none;
	}
}
// a[href^="http"]:empty::before {
a[href=""]::before,
a:not([href])::before {
	content: attr(href);
}
.hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}
/* Прижать футер к низу */
.site-body {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	// overflow-x: hidden;
}
.site-header {}
.site-main {
	flex: 1 0 auto;
}
.site-footer {
	margin-top: auto;
	flex-shrink: 0;
}
/* --Прижать футер к низу */
/* контейнеры */
.box {
	width: 100%;
	max-width: 100rem;
	padding: 0 calc(var(--gutter-x) * 0.5);
	margin: 0 auto;
	@media (min-width: 1720px) {
		padding: 0;
	}
}
@include for_breakpoint(md) {
	.box-md {
		width: 100%;
		max-width: 100rem;
		padding: 0 calc(var(--gutter-x) * 0.5);
		margin: 0 auto;
		@media (min-width: 1720px) {
			padding: 0;
		}
	}
	.box-md-none {
		padding: 0;
	}
}
@include for_breakpoint(lg) {
	.box-lg {
		width: 100%;
		max-width: 100rem;
		padding: 0 calc(var(--gutter-x) * 0.5);
		margin: 0 auto;
		@media (min-width: 1720px) {
			padding: 0;
		}
	}
}
@include for_breakpoint(xl) {
	.box-xl {
		width: 100%;
		max-width: 100rem;
		padding: 0 calc(var(--gutter-x) * 0.5);
		margin: 0 auto;
		@media (min-width: 1720px) {
			padding: 0;
		}
	}
}
/* --контейнеры */
/* сетки */
.grid-col {
	--g-gap: 40px;
	--g-col: 2;
	@include for_breakpoint(md) {
		display: grid;
		grid-template-columns: repeat(var(--g-col), 1fr);
		gap: var(--g-gap);
		&--4-7 {
			grid-template-columns: 4fr 7fr;
		}
		&--5-7 {
			grid-template-columns: 5fr 7fr;
		}
	}
	&--xl-1-2 {
		@include for_breakpoint(xl) {
			// --g-gap: 70px;
			grid-template-columns: 1fr 2fr;
		}
	}
	@media(min-width:1550px) {
		--g-gap: 136px;
	}
	&__title {
		@include for_breakpoint(md) {
			margin: 0;
			margin-top: -10px;
		}
		@include for_breakpoint(xl) {
			span:not([class]) {
				margin-top: 30px;
			}
		}
	}
	&__content {}
}
/* --сетки */
@include for_breakpoint(md-max) {
	.hidden-md {
		display: none;
	}
}
.text-nowrap {
	white-space: nowrap;
}
.list-unstyled {
	padding: 0;
	margin: 0;
	list-style-type: none;
}
.bg-black {
	background-color: var(--black);
}
.hr-ticker {
	height: 43px;
	background-repeat: repeat-x;
	background-position: 0 50%;
	background-size: auto 33px;
	animation-name: ticker;
	animation-duration: 299s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-fill-mode: forwards;
	&[data-item="capybara"] {
		background-size: auto 20px;
	}
	@include for_breakpoint(sm) {
		& {
			animation-duration: 900s;
		}
	}
	@include for_breakpoint(lg) {
		& {
			animation-duration: 2700s;
		}
	}
	@include for_breakpoint(xl) {
		background-size: auto 56px;
		height: 66px;
		&[data-item="capybara"] {
			background-size: auto 28px;
		}
	}
}
@keyframes ticker {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: -3999vw;
	}
}
h1 {
	margin-bottom: 20px;
	font-size: var(--fs-title);
	font-weight: 500;
	line-height: 1.3;
	letter-spacing: 0.03em;
	& a {
		letter-spacing: 0.03em;
	}
}
h1,
h2,
h3 {
	margin-bottom: 0.75em;
	font-size: var(--fs-title);
	font-weight: 500;
	line-height: 1.3;
	letter-spacing: 0.03em;
	& span:not([class]),
	& span[class*=aos] {
		display: block;
		margin-top: 7px;
		font-size: var(--fs-accent);
		font-weight: 300;
		letter-spacing: normal;
	}
}
h1,
h2,
h3 {
	i {
		font-style: normal;
		color: var(--yellow);
	}
}
button {
	cursor: pointer;
}
.site-main section:last-child {
	margin-bottom: 0 !important;
}
section,
.site-main > article:not([class]):first-child:not(:last-child) {
	margin-bottom: 60px;
	& + .hr-ticker {
		margin-top: -60px;
	}
	@include for_breakpoint(md) {
		margin-bottom: 80px;
		& + .hr-ticker {
			margin-top: -80px;
		}
	}
	@include for_breakpoint(xl) {
		margin-bottom: 150px;
		& + .hr-ticker {
			margin-top: -150px;
		}
	}
	@media(min-width:1550px) {
		margin-bottom: 250px;
		& + .hr-ticker {
			margin-top: -250px;
		}
	}
}
#overlay {
	position: fixed;
	/* Sit on top of the page content */
	display: none;
	/* Hidden by default */
	width: 100%;
	/* Full width (cover the whole page) */
	height: 100%;
	/* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: transparent;
	/* Black background with opacity */
	z-index: 2;
	/* Specify a stack order in case you're using a different order for other elements */
	// cursor: pointer;
	/* Add a pointer on hover */
	animation: overlay 0.3s ease 0.25s both;
}
@keyframes overlay {
	0% {
		background-color: transparent;
	}
	100% {
		background-color: rgba(0, 0, 0, 0.8);
	}
}
.img-overlay {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		// transform: translate(-50%, -50%);
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}
}
.text-gold {
	color: var(--yellow);
}
.text-underline {
	text-decoration: underline;
}
.big-gray-title {
	margin: 0;
	color: transparent;
	text-decoration: none;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 20vw;
	font-weight: 700;
	line-height: 0.7;
	text-align: center;
	text-transform: uppercase;
	background-image: linear-gradient(0deg,
			#373737,
			#373737 50%,
			#fff 50%,
			#fff 100%);
	background-size: 100% 200%;
	background-position: 100% 100%;
	@include for_breakpoint(sm) {
		font-size: 15vw;
		margin-bottom: -5px;
	}
	@include for_breakpoint(xl) {
		margin-bottom: -10px;
	}
	@media (min-width: 1720px) {
		font-size: 300px;
	}
}
.site-main {
	&__section-lead {
		@media(min-width:1550px) {
			margin-bottom: 122px;
		}
	}
}
.section-lead {
	@include for_breakpoint(md) {
		display: flex;
		flex-direction: row-reverse;
		gap: 30px;
	}
	@include for_breakpoint(lg) {
		align-items: center;
	}
	@media(min-width:1550px) {
		align-items: unset;
	}
	&__img-wrap {
		margin-bottom: 20px;
		img {
			width: 100%;
		}
		@include for_breakpoint(md) {
			width: 50%;
			flex-shrink: 0;
		}
	}
	&__desc {
		font-size: var(--fs-accent);
		p {
			margin: 0;
		}
		@media(min-width:1550px) {
			margin-top: 22px;
		}
	}
	&__title {
		margin-top: 0;
		margin-bottom: 20px;
		@include for_breakpoint(md) {
			margin-bottom: 30px;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 40px;
		}
		@media(min-width:1550px) {
			margin-bottom: 50px;
		}
	}
	&__btn {
		width: 100%;
		margin-top: 16px;
		@include for_breakpoint(md) {
			margin-top: 20px;
		}
		@include for_breakpoint(xl) {
			margin-top: 30px;
		}
		@media(min-width:1550px) {
			margin-top: 44px;
		}
	}
}
.list {
	--gap: 10px;
	--columns: 1;
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	gap: var(--gap);
	width: 100%;
	@media(min-width:1550px) {
		--gap: 20px;
	}
	&__item {
		width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
	}
	@include for_breakpoint(xl-max) {
		&--compress {
			gap: 0;
			& .list {
				&__item {
					margin-top: -1px;
				}
			}
			& .card {
				padding: 20px;
			}
		}
	}
	&--xl-col-2 {
		@include for_breakpoint(xl) {
			--gap: 20px;
			--columns: 2;
		}
	}
}
.card-list {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	@include for_breakpoint(sm) {
		margin: 0 -5px;
	}
	@media(min-width:1550px) {
		margin: 0 -10px;
	}
	&__item {
		padding-bottom: 10px;
		width: 100%;
		@include for_breakpoint(sm) {
			padding: 5px;
			width: 50%;
		}
		@include for_breakpoint(lg) {
			width: 25%;
		}
		@media(min-width:1550px) {
			padding: 10px;
		}
	}
}
.card {
	display: flex;
	flex-direction: column;
	align-items: start;
	height: 100%;
	padding: 20px 12px 20px 15px;
	border: 1px solid #4A4A4A;
	line-height: 1.3;
	@include for_breakpoint(sm) {
		padding: 30px 20px;
		gap: 20px;
		&--sm-hor {
			flex-direction: row;
		}
	}
	@include for_breakpoint(md) {}
	@include for_breakpoint(lg) {
		padding: 30px 20px 35px;
		gap: unset;
		&--sm-hor {
			flex-direction: column;
		}
	}
	@include for_breakpoint(xl) {
		padding: 30px 24px 40px;
	}
	@media(min-width:1550px) {
		padding: 40px 30px 42px;
	}
	&__content {}
	&__img-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 48px;
		height: 48px;
		margin-bottom: 20px;
		@include for_breakpoint(xl) {
			width: 64px;
			height: 64px;
			margin-bottom: 36px;
		}
		@media(min-width:1550px) {
			width: 80px;
			height: 80px;
			margin-bottom: 45px;
			margin-left: 10px;
		}
	}
	&__img {
		display: block;
		width: 100%;
	}
	&__title {
		margin: 0;
		margin-bottom: 10px;
		font-size: var(--fs);
		font-weight: 500;
		letter-spacing: 0.03em;
		@include for_breakpoint(xl) {
			margin-bottom: 15px;
			line-height: 1.4;
		}
		@media(min-width:1550px) {
			line-height: 1.5;
		}
		&--upcase {
			text-transform: uppercase;
		}
		&--light {
			font-weight: 300;
		}
		&--more {
			@include for_breakpoint(xl) {
				font-size: 1.375rem;
			}
			@media(min-width:1550px) {
				& + .card__desc {
					font-size: 1.375rem;
				}
			}
		}
		&--md {
			font-size: 2.25rem;
			@include for_breakpoint(xl) {
				font-size: 3.75rem;
			}
			@media(min-width:1550px) {
				font-size: 4.5rem;
			}
		}
		&--lg {
			font-size: 3rem;
			@include for_breakpoint(xl) {
				font-size: 4rem;
			}
			@media(min-width:1550px) {
				font-size: 6rem;
			}
		}
		&--gold {
			color: var(--yellow);
		}
	}
	&__desc {
		margin: 0;
		color: #898989;
		font-size: var(--fs);
		line-height: 1.5;
		&--sm {
			font-size: 0.75rem;
			@include for_breakpoint(xl) {
				font-size: 1rem;
			}
			@include for_breakpoint(xl) {
				font-size: 1rem;
			}
		}
	}
	&--border-left {
		border-left: 2px solid var(--yellow);
	}
	&--before-left {
		position: relative;
		border-left-color: transparent;
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			display: block;
			width: 2px;
			height: 100%;
			background-color: var(--yellow);
		}
		// border-left: 2px solid var(--yellow);
	}
	&--black {
		border: none;
		background-color: var(--black);
	}
	&--dot-light {
		position: relative;
		overflow: hidden;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: -21px;
			transform: translate(0, -50%);
			display: block;
			width: 52px;
			height: 52px;
			border-radius: 50%;
			background-image: radial-gradient(rgba(144, 77, 160, 0.5),
					rgba(144, 77, 160, 0.5));
			filter: blur(20px);
			@include for_breakpoint(md) {
				top: unset;
				right: unset;
				left: 50%;
				bottom: -21px;
				transform: translate(-50%, 0);
				width: 72px;
				height: 72px;
			}
			@include for_breakpoint(xl) {
				bottom: unset;
				left: unset;
				top: 50%;
				right: -21px;
				transform: translate(0, -50%);
			}
			@media(min-width:1550px) {
				width: 82px;
				height: 82px;
				filter: blur(20px);
			}
		}
	}
}
.ui-slash {
	position: relative;
	bottom: 2px;
	color: #4A4A4A;
}
.serial-card-list {
	padding: 0;
	margin: 0;
	list-style: none;
	&__item {
		position: relative;
		padding: 20px 0;
		@include for_breakpoint(xl) {
			padding: 30px 0;
		}
		&:first-of-type {
			padding-top: 0;
		}
		&:not(:first-of-type)::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: 100%;
			height: 1px;
			background-color: #4A4A4A;
		}
	}
}
.serial-card {
	display: flex;
	align-items: flex-start;
	gap: 15px;
	@media(min-width:1550px) {
		gap: 34px;
	}
	&__pos {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--black);
		width: 40px;
		height: 40px;
		color: var(--yellow);
		font-size: 1.75rem;
		font-weight: 500;
		line-height: 1.5;
		@include for_breakpoint(xl) {
			font-size: 2.5rem;
			width: 52px;
			height: 52px;
		}
	}
	&__icon {
		flex-shrink: 0;
		width: 40px;
		height: 40px;
		background-color: var(--black);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px auto;
		@include for_breakpoint(xl) {
			width: 52px;
			height: 52px;
			background-size: 32px auto;
		}
	}
	&__content {}
	&__title {
		margin: 0;
		margin-bottom: 5px;
		font-size: var(--fs);
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.03em;
		@include for_breakpoint(xl) {
			margin-bottom: 10px;
			font-size: 1.25rem;
			line-height: 1.4;
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
			line-height: 1.5;
		}
	}
	&__desc {
		margin: 0;
		color: #898989;
		font-size: 0.75rem;
		line-height: 1.5;
		@include for_breakpoint(xl) {
			font-size: 1.125rem;
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
		}
	}
}
// навигация слайдера
.tns-outer {
	position: relative;
}
.tns-nav {
	position: absolute;
	display: flex;
	gap: 20px;
	margin-top: 16px;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	button {
		width: 6px;
		height: 6px;
		padding: 0;
		border: none;
		background-color: #4A4A4A;
		@include for_breakpoint(sm) {
			width: 8px;
			height: 8px;
		}
		&.tns-nav-active {
			background-color: var(--yellow);
		}
	}
}
.tns-controls {
	[data-controls] {
		position: absolute;
		z-index: 100;
		top: 50%;
		transform: translate(0, -50%);
		width: 64px;
		height: 64px;
		// height: 100%;
		padding: 16px;
		border: none;
		border-radius: 50%;
		background-color: rgba(26,26,26,0.3);
		transition: all 0.2s ease-in-out;
		@media (min-width: 1550px) {
			svg {
				height: 32px;
				width: 32px;
			}
		}
		& svg path {
			transition: fill 0.2s ease-in-out;
		}
		&:hover {
			background-color: rgba(26,26,26,0.5);
			& svg path {
				fill: var(--yellow);
			}
		}
	}
	[data-controls="prev"] {
		left: 8px;
	}
	[data-controls="next"] {
		right: 8px;
	}
}
// tooltip and popover
.tooltip {
	position: relative;
	display: block;
	padding: 0;
	// border: 1px solid #fff;
	border: none;
	// border-radius: 50%;
	width: 18px;
	height: 18px;
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	@include for_breakpoint(md) {
		width: 20px;
		height: 20px;
	}
	@include for_breakpoint(lg) {
		width: 24px;
		height: 24px;
	}
}
.tippy-box[data-theme ~='gray'] {
	background-color: var(--gray);
	@include for_breakpoint(xl) {
		font-size: 16px;
	}
	.tippy-arrow {
		background-color: var(--gray);
	}
}
.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.clearfix {
	content: "";
	clear: both;
	display: table;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.content {
	font-size: 14px;
	line-height: 1.5;
	@include for_breakpoint(md) {
		font-size: 16px;
	}
	@include for_breakpoint(xl) {
		font-size: 20px;
	}
	&--float {
		@include for_breakpoint(md-max) {
			.clearfix {
				display: flex;
				flex-direction: column-reverse;
				.float-left,
				.float-right {
					float: none;
				}
			}
		}
		@include for_breakpoint(md) {
			.float-left,
			.float-right {
				margin: 14px 0 0;
				width: 33.333%;
				img {
					border: 1px solid #ccc;
				}
			}
			.float-left {
				margin-right: 20px;
			}
			.float-right {
				margin-left: 20px;
			}
		}
	}
	&.content--wrap {
		position: relative;
		padding: 40px 0 60px;
		color: #000;
		background-color: #fff;
		@include for_breakpoint(md) {
			padding: 40px 0 30px;
		}
		@include for_breakpoint(xl) {
			padding: 80px 0 100px;
		}
		@media (min-width: 1550px) {
			padding: 60px 0 80px;
			max-width: 1020px;
			margin: 0 auto;
		}
		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			display: block;
			width: 100vw;
			height: 100%;
			background-color: #fff;
		}
		&::before {
			right: 100%;
		}
		&::after {
			left: 100%;
		}
	}
	& > h2:first-child,
	& > h3:first-child,
	.text-content > h2:first-child,
	.text-content > h3:first-child {
		margin-top: 0;
	}
	h2,
	h3 {
		font-weight: 500;
		letter-spacing: 0.03em;
		line-height: 1.3;
	}
	h2 {
		margin: 55px 0 15px;
		font-size: 20px;
		@include for_breakpoint(md) {
			font-size: 24px;
		}
		@include for_breakpoint(xl) {
			margin-top: 70px;
			font-size: 28px;
		}
	}
	h3 {
		font-size: 18px;
		@include for_breakpoint(md) {
			font-size: 20px;
		}
		@include for_breakpoint(xl) {
			font-size: 24px;
		}
		@include for_breakpoint(xxl) {
			margin-top: 36px;
		}
	}
	p {}
	b {
		font-weight: 500;
	}
	a:not([class]) {
		color: #16549B;
		--color-underline: #16549B;
		text-decoration: none;
		background-image: linear-gradient(0deg,
				transparent 0,
				transparent 2px,
				var(--color-underline) 2px,
				var(--color-underline) 3px,
				transparent 3px,
				transparent 100%);
		@media(min-width:1550px) {
			background-image: linear-gradient(0deg,
					transparent 0,
					transparent 0.07em,
					var(--color-underline) 0.07em,
					var(--color-underline) calc(0.07em + 1px),
					transparent calc(0.07em + 1px),
					transparent 100%),
		}
		&:hover {
			background-image: none;
		}
	}
	ul {
		padding-left: 30px;
		li {
			margin-bottom: 5px;
			// font-size: 14px;
			// font-style: italic;
			& > p {
				margin: 0;
			}
			&::marker {
				color: var(--yellow);
				// line-height: 1.15;
				@include for_breakpoint(lg) {
					font-size: 20px;					
				}
				@include for_breakpoint(xl) {
					// font-size: 28px;
					// line-height: 1;
				}
			}
			@include for_breakpoint(md) {
				margin-bottom: 12px;
			}
			@include for_breakpoint(xl) {
				// font-size: 18px;
			}
			@include for_breakpoint(xxl) {
				// font-size: 20px;
			}
		}
		@include for_breakpoint(xl) {
			padding-left: 46px;
		}
	}
	ol {
		list-style-type: none;
		counter-reset: item;
		padding-left: 10px;
		li {
			position: relative;
			counter-increment: item;
			display: flex;
			align-items: flex-start;
			gap: 15px;
			margin-bottom: 15px;
			// font-size: 14px;
			// font-style: italic;
			& > p {
				margin: 0;
			}
			&::before {
				content: "";
				position: relative;
				top: 2px;
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 24px;
				height: 24px;
				padding: 2px;
				background-color: var(--gray);
			}
			&::after {
				content: counter(item);
				position: absolute;
				top: 14px;
				left: 12px;
				transform: translate(-50%, -50%);
				color: var(--yellow);
				font-size: 14px;
				font-style: normal;
			}
			@include for_breakpoint(md) {
			}
			@include for_breakpoint(xl) {
				margin-bottom: 12px;
				// font-size: 18px;
			}
			@include for_breakpoint(xxl) {
				// font-size: 20px;
			}
		}
		@include for_breakpoint(xl) {
			padding-left: 24px;
		}
	}
	#gallery {
		padding: 0;
		margin: 0;
		list-style-type: none;

		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		li {
			width: calc(50% - 10px);
		}
	}
	img[data-src],
	li[data-src] {
		cursor: pointer;
	}
	figure {
		margin: 30px 0 40px;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		@include for_breakpoint(md) {
			margin: 40px 0 40px;
		}
		@include for_breakpoint(xl) {
			margin: 60px 0 60px;
		}
	}
	figcaption {
		margin-top: 15px;
		color: #898989;
		font-size: 12px;
		font-style: italic;
		text-align: left;
		@include for_breakpoint(md) {
			margin-top: 20px;
		}
		@include for_breakpoint(xl) {
			font-size: 16px;
		}
	}
	blockquote {
		position: relative;
		padding: 14px 14px;
		padding-right: 50px;
		margin: 40px 0;
		border: 2px solid var(--yellow);
		background-color: #fff;
		@include for_breakpoint(md) {
			padding: 35px 20px;
			padding-right: 100px;
		}
		@include for_breakpoint(xl) {
			padding: 30px;
			padding-right: 100px;
			margin: 60px 0;
		}
		&::after {
			position: absolute;
			top: 18px;
			right: 8px;
			content: "";
			display: block;
			width: 30px;
			height: 100%;
			background-image: url("../img/quote.svg");
			background-repeat: no-repeat;
			background-position: top center;
			background-size: contain;
			@include for_breakpoint(md) {
				top: 38px;
				right: 18px;
				width: 40px;
			}
			@include for_breakpoint(xl) {
				right: 40px;
			}
		}
	}
	table {
		caption {
			margin-top: 15px;
			color: #898989;
			font-size: 12px;
			font-style: italic;
			text-align: left;
			@include for_breakpoint(md) {
				margin-top: 20px;
			}
			@include for_breakpoint(xl) {
				font-size: 16px;
			}
		}
	}
	@include for_breakpoint(sm) {
		table tr.mobile {
			display: none;
		}
		table {
			caption-side: bottom;
			border-collapse: collapse;
			width: 100%;
			margin-bottom: 1rem;
			vertical-align: top;
			caption {
				margin-top: 15px;
				color: #898989;
				font-size: 12px;
				font-style: italic;
				text-align: left;
				@include for_breakpoint(md) {
					margin-top: 20px;
				}
				@include for_breakpoint(xl) {
					font-size: 16px;
				}
			}
		}
		table > :not(caption) > * > * {
			padding: 14px 20px;
			border: 1px solid #2D2D2D;
		}
		table > tbody {
			vertical-align: inherit;
		}
		table > thead,
		table tbody > tr:first-child {
			vertical-align: bottom;
			color: #fff;
			background-color: #2D2D2D;
			& th {
				padding: 18px 20px;
				font-size: 16px;
				letter-spacing: 0.03em;
				text-align-last: left;
				text-transform: uppercase;
				&:first-child {
					border-right-color: #898989;
				}
				&:last-child {
					border-left-color: #898989;
				}
				&:not(:first-child):not(:last-child) {
					border-left-color: #898989;
					border-right-color: #898989;
				}
			}
		}
	}
	@include for_breakpoint(sm-max) {
		table {
			width: 100%;
			display: flex;
    		flex-direction: column-reverse;
		} 
		table tr:not(.mobile) {
			display: none;
		}
		table .mobile {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
		table .mobile summary {
			position: relative;
			padding: 15px 14px;
			color: #fff;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
			list-style: none;
			letter-spacing: 0.03em;
			background-color: #2D2D2D;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&::after {
				content: "+";
				position: absolute;
				top: 50%;
				right: 14px;
				transform: translate(0, -50%);
				display: inline-block;
				font-size: 36px;
				font-weight: 300;
				transition: all 0.3s ease-in-out;
			}
		}
		table .mobile details[open] > summary {
			color: var(--yellow);
		}
		table .mobile details[open] > summary::after {
			color: var(--yellow);
			transform: translate(0, -50%) rotate(45deg);
		}
		table .mobile td div:first-of-type dl {
			margin: 0;
		}
		table .mobile td div:first-of-type dt {
			padding-top: 20px;
			padding-bottom: 10px;
			font-size: 16px;
			font-weight: 500;
			text-transform: uppercase;
		}
		table .mobile td div:first-of-type dt,
		table .mobile td div:first-of-type dd {
			padding-left: 14px;
			padding-right: 14px;
			margin-left: unset;
			border: 1px solid #2D2D2D;
			border-top: none;
			border-bottom: none;
		}
		table .mobile td div:first-of-type dd {
			padding-bottom: 20px;
			border-bottom: 1px solid #2D2D2D;
		}
		table .mobile td div:first-of-type {
			height: 100%;
    		overflow: hidden;
			max-height: 0;
			transition: max-height 0.5s ease;
		}
		table .mobile details[open] + div:first-of-type {
			max-height: 500px;
			margin-bottom: 5px;
		}
	}
	&--subjects {
		& div:nth-child(1) {
			color: #FFFBFB;
			background-color: #2D2D2D;
			& > p {
				margin-top: 0;
				font-weight: 500;
				text-transform: uppercase;
			}
			& ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style: none;
				display: flex;
				flex-direction: column;
				justify-content: start;
				li {
					a {
						text-decoration: underline;
						transition: color 0.3s ease-in-out;
						&:hover, &:focus {
							color: var(--yellow)
						}
						&:active {
	
						}
					}
				}
			}
			@include for_breakpoint(xxl-max) {
				summary {
					position: relative;
					padding: 15px 14px;
					color: #fff;
					font-size: 16px;
					font-weight: 500;
					text-transform: uppercase;
					list-style: none;
					letter-spacing: 0.03em;
					background-color: #2D2D2D;
					cursor: pointer;
					transition: all 0.3s ease-in-out;
					&::after {
						content: "+";
						position: absolute;
						top: 50%;
						right: 14px;
						transform: translate(0, -50%);
						display: inline-block;
						font-size: 36px;
						font-weight: 300;
						transition: all 0.3s ease-in-out;
					}
					@include for_breakpoint(md) {
						padding: 20px;
						&::after {
							right: 20px;
						}
					}
					@include for_breakpoint(xl) {
						padding: 34px 36px;
						font-size: 22px;
						&::after {
							font-size: 64px;
							font-weight: 300;
							right: 36px;
						}
					}
				}
				details[open] > summary {
					color: var(--yellow);
				}
				details[open] > summary::after {
					color: var(--yellow);
					transform: translate(0, -50%) rotate(45deg);
				}
				details + div {
					padding-left: 14px;
					padding-right: 14px;
					height: 100%;
					overflow: hidden;
					max-height: 0;
					transition: max-height 0.5s ease;
					@include for_breakpoint(md) {
						padding-left: 20px;
						padding-right: 20px;
					}
				}
				details[open] + div:first-of-type {
					max-height: 500px;
					margin-bottom: 5px;
				}
			}
			@media (min-width: 1550px) {
				summary {
					list-style: none;
					font-size: 22px;
					text-transform: uppercase;
				}
				padding: 30px 20px;
				details + div {
					overflow-y: auto;
					max-height: 60vh;
				}
			}
		}
		@media (min-width: 1550px) {
			max-width: unset;
			display: flex;
			flex-direction: row-reverse;
			gap: 60px;
			& div:nth-child(1) {
				flex: 1 0 auto;
				align-self: flex-start;
				max-width: 520px;
				position: -webkit-sticky;
				position: sticky;
				top: calc(var(--header-hight) + 60px);;
			}
		}
	}
}