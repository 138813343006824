.about-lead {
	position: relative;
	&__img-wrap {
		position: absolute;
		top: 0;
		// right: 0;
		// bottom: 0;
		left: 50%;
		display: block;
		width: 100%;
		height: 100%;
		// max-width: 1820px;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		transform: translate(-50%, 0);
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.7);
		}
	}
	&__content {
		position: relative;
		z-index: 1;
		padding: 14px 0 16px;
        margin-top: -9px;
		@include for_breakpoint(md) {
            margin-top: -26px;
			padding: 34px 0 34px;
		}
		@include for_breakpoint(xl) {
            margin-top: unset;
			padding: 40px 0 64px;
		}
		@media (min-width: 1550px) {
			padding: 102px 0 120px;
		}
	}
	&__title-wrap {}
	&__title {
		display: flex;
		flex-flow: column;
		justify-content: center;
		text-align: center;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
		@include for_breakpoint(md) {
			text-align: left;
			font-size: 20px;
		}
		@include for_breakpoint(xl) {
			font-size: 30px;
		}
		br {
			display: none;
			@include for_breakpoint(md) {
				display: block;
			}
		}
	}
	&__studio {
		font-size: 30px;
		font-weight: 500;
		line-height: 1;
		letter-spacing: 0.03em;
		@include for_breakpoint(md) {
			font-size: 58px;
		}
		@include for_breakpoint(xl) {
			font-size: 80px;
		}
		@media (min-width: 1550px) {
			font-size: 120px;
		}
	}
	&__name {
		display: flex;
		flex-flow: column;
		justify-content: center;
		gap: 3px;
		margin-bottom: 32px;
		font-size: 30px;
		font-weight: 500;
		line-height: 1;
		letter-spacing: 0.03em;
		@include for_breakpoint(sm) {
			flex-flow: row;
			align-items: flex-end;
			gap: 10px;
		}
		@include for_breakpoint(md) {
			justify-content: start;
			gap: 16px;
			margin-bottom: 32px;
			font-size: 58px;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 64px;
			font-size: 80px;
		}
		@media (min-width: 1550px) {
            margin-bottom: 44px;
			font-size: 120px;
		}
		& > i {
			font-size: 48px;
			font-weight: 700;
			@include for_breakpoint(sm) {
				position: relative;
				top: 2px;
			}
			@include for_breakpoint(md) {
				top: 7px;
				font-size: 96px;
			}
			@include for_breakpoint(xl) {
				top: 8px;
				font-size: 140px;
			}
			@media (min-width:1280px) {
				top: 12px;
				font-size: 160px;
			}
			@media (min-width: 1550px) {
				top: 8px;
				font-size: 180px;
			}
			@media (min-width: 1800px) {
				top: 11px;
				font-size: 200px;
			}
		}
	}
}
.about-counters {
	@include for_breakpoint(md) {
        display: grid;
		grid-template-columns: 3fr 1fr;
		grid-template-rows: repeat(4, auto);
	}
	@include for_breakpoint(xl) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(3, auto);
	}
    @media (min-width: 1550px) {
        column-gap: 40px;
    }
	&__text {
		font-size: 14px;
		line-height: 1.5;
		text-align: center;
		@include for_breakpoint(md) {
			font-size: 16px;
			text-align: left;
		}
		@include for_breakpoint(xl) {
            margin-left: 44px;
			font-size: 18px;
		}
		@media (min-width: 1550px) {
            margin-left: 0;
			font-size: 22px;
		}
        &--intro {
            @include for_breakpoint(md-max) {
                margin-bottom: 40px;
            }
            @include for_breakpoint(md) {
                grid-area: 2 / 1 / 3 / 2;
                margin-top: 36px;
                margin-bottom: 4px;
            }
            @include for_breakpoint(xl) {
                grid-area: 1 / 2 / 2 / 3;
                margin-top: 70px;
                margin-bottom: 0;
            }
            @media (min-width: 1550px) {
                margin-top: 60px;
            }
        }
        &--mid {
            @include for_breakpoint(md-max) {
                margin-top: 62px;
            }
            @include for_breakpoint(md) {
                grid-area: 3 / 1 / 4 / 2;
            }
            @include for_breakpoint(xl) {
                grid-area: 2 / 2 / 3 / 3;
                margin-top: 0;
                margin-bottom: 0;
            }
            @media (min-width: 1550px) {
                margin-top: 6px;
            }
        }
        &--outro {
            @include for_breakpoint(md-max) {
                margin-top: 20px;
            }
            @include for_breakpoint(md) {
                grid-area: 4 / 1 / 5 / 2;
            }
            @include for_breakpoint(xl) {
                grid-area: 3 / 2 / 4 / 3;
                margin-top: 0;
            }
            @media (min-width: 1550px) {
                margin-top: 12px;
            }
        }
	}
	&__list-wrap {
		@include for_breakpoint(md) {
			grid-area: 1 / 1 / 2 / 3;
		}
        @include for_breakpoint(xl) {
            grid-area: 1 / 1 / 4 / 2;
            .card-counter-list {
                flex-wrap: wrap;
            }
            .card-counter-list__item {
                width: 50%;
            }
        }
	}
}
.about-ratings {
    display: flex;
    flex-flow: column;
    text-align: center;
    @include for_breakpoint(md) {
        text-align: left;
    }
    &__title {
        @include for_breakpoint(md) {
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: 85px;
        }
        @include for_breakpoint(xl) {
            gap: 135px;
        }
        @media (min-width: 1550px) {
            gap: 190px;
        }
    }
    &__list {
        @include for_breakpoint(md-max) {
            flex-flow: column;
            align-items: center;
            gap: 15px;
            & .card-rating {
                display: flex;
                flex-flow: row;
                align-items: flex-end;
                gap: 10px;
                &__img {
                    margin: unset;
                    & + div {
                        display: flex;
                        align-items: flex-end;
                        gap: 10px;
                        min-width: 200px;
                    }
                }
                &__number {
                    margin-bottom: 0;
                }
            }
        }
        @include for_breakpoint(xl) {
            & .card-rating {
                &__img {
                    position: static;
                    transform: none;
                }
            }
        }
    }
}