/* кнопки */
a:not([class]) {
	transition: all 0.2s ease-in-out;
	&:hover {
		color: var(--yellow);
	}
	&:active {}
}
.link {
	--color-underline: #fff;
	text-decoration: none;
	background-image: linear-gradient(0deg,
			transparent 0,
			transparent 2px,
			var(--color-underline) 2px,
			var(--color-underline) 3px,
			transparent 3px,
			transparent 100%);
	@media(min-width:1550px) {
		background-image: linear-gradient(0deg,
				transparent 0,
				transparent 0.07em,
				var(--color-underline) 0.07em,
				var(--color-underline) calc(0.07em + 1px),
				transparent calc(0.07em + 1px),
				transparent 100%),
	}
	&:hover {
		background-image: none;
	}
	&--gold {
		color: var(--yellow);
		--color-underline: var(--yellow);
	}
}
.link-filled {
	position: relative;
	display: inline-block;
	text-decoration: none;
	// transition: all 2s ease-in-out;
	&::before,
	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
		display: block;
		width: 100%;
		height: 100%;
		opacity: 1;
		transition: width 0.3s ease-in-out;
		transition-behavior: allow-discrete;
	}
	&::before {
		width: 0;
		background-image: linear-gradient(0deg,
				transparent 0,
				transparent 2px,
				var(--yellow) 2px,
				var(--yellow) 3px,
				transparent 3px,
				transparent 100%);
		@media(min-width:1550px) {
			background-image: linear-gradient(0deg,
					transparent 0,
					transparent 0.12em,
					var(--yellow) 0.12em,
					var(--yellow) calc(0.12em + 1px),
					transparent calc(0.12em + 1px),
					transparent 100%),
		}
	}
	&::after {
		z-index: -2;
		background-image: linear-gradient(0deg,
				transparent 0,
				transparent 2px,
				#898989 2px,
				#898989 3px,
				transparent 3px,
				transparent 100%);
		@media(min-width:1550px) {
			background-image: linear-gradient(0deg,
					transparent 0,
					transparent 0.12em,
					#898989 0.12em,
					#898989 calc(0.12em + 1px),
					transparent calc(0.12em + 1px),
					transparent 100%),
		}
	}
	&:hover::before {
		width: 100%;
	}
	// &:hover {
	// 	background: none;
	// }
}
.btn-inline {
	display: inline-block;
	padding: 0;
	border: none;
	color: inherit;
	font-weight: 300;
	font-size: inherit;
	border-bottom: 1px solid #fff;
	background-color: transparent;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	&:hover,
	&:focus {
		color: #fff;
	}
	&:active {
		color: var(--yellow);
	}
	&--gray {
		color: #898989;
		border-bottom: none;
		
		--color-underline: #898989;
		text-decoration: none;
		background-image: linear-gradient(0deg,
				transparent 0,
				transparent 2px,
				var(--color-underline) 2px,
				var(--color-underline) 3px,
				transparent 3px,
				transparent 100%);
		@media(min-width:1550px) {
			background-image: linear-gradient(0deg,
					transparent 0,
					transparent 0.07em,
					var(--color-underline) 0.07em,
					var(--color-underline) calc(0.07em + 1px),
					transparent calc(0.07em + 1px),
					transparent 100%),
		}
		&:hover {
			color: #898989;
			background-image: none;
		}


	}
}
.btn {
	--color: #fff;
	--border-color: #4A4A4A;
	--bg-color: transparent;

	--hover-color: #fff;
	--hover-border-color: #fff;
	--hover-bg-color: var(--yellow);

	--active-color: var(--yellow);
	--active-border-color: var(--yellow);
	--active-bg-color: transparent;

	display: block;
	padding: 16px 24px;
	border: 1px solid var(--border-color);
	border-radius: 2px;
	color: var(--color);
	font-size: 15px;
	font-weight: 300;
	text-align: center;
	letter-spacing: 0.12rem;
	text-transform: uppercase;
	cursor: pointer;
	background: none;
	background-color: var(--bg-color);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 0 100%;
	&:not(:active) {
		transition: color 0.3s ease-in-out,
		border-color 0.3s ease-in-out,
		background-color 0.3s ease-in-out,
		background-size 0.3s ease-in-out;
	}
	@include for_breakpoint(md) {
		max-width: 240px;
		padding: 16px 24px;
		letter-spacing: 0.03rem;
	}
	@include for_breakpoint(xl) {
		font-size: 1.25rem;
		max-width: 300px;
	}
	@media(min-width:1550px) {
		padding: 20px 24px;
		font-size: 1.375rem;
		letter-spacing: 0.12em;
		max-width: 385px;
	}
	&:hover {
		color: var(--hover-color);
		border-color: var(--hover-border-color);
		// background-color: var(--hover-bg-color);
	}
	&:active {
		color: var(--active-color);
		border-color: var(--active-border-color);
		background-color: var(--active-bg-color);
	}
	&--sm {
		padding: 0.5rem 0.75rem;
		font-size: 0.75rem;
	}
	&--black {
		--color: #fff;
		--border-color: var(--black);
		--bg-color: var(--black);

		--hover-color: var(--yellow);
		--hover-border-color: var(--black);
		--hover-bg-color: var(--black);

		--active-color: var(--black);
		--active-border-color: var(--black);
		--active-bg-color: var(--yellow);

		background-image: linear-gradient(90deg,
			var(--hover-bg-color) 0%,
			var(--hover-bg-color) 100%);
		&:hover {
			background-size: 100% 100%;
		}
		&:active {
			background-image: none;
		}
	}
	&--yellow {
		--color: var(--yellow);
		--border-color: var(--yellow);
		// --bg-color: var(--yellow);		

		--hover-color: var(--black);
		--hover-border-color: var(--yellow);
		// --hover-bg-color: var(--yellow);

		--active-color: var(--yellow);
		--active-border-color: var(--yellow);
		// --active-bg-color: var(--black);

		background-image: linear-gradient(90deg,
			var(--hover-bg-color) 0%,
			var(--hover-bg-color) 100%);
		&:hover {
			background-size: 100% 100%;
		}
		&:active {
			background-image: none;
		}
	}
}
@property --a {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}
@property --fill-color {
	syntax: '<color>';
	initial-value: #2D2D2D;
	inherits: true;
}
.btn-svg {
	--a: 0deg;
	--fill-color: var(--gray);
	--border-color: #535353;
	--border-hover-color: #fff;
	--border-width: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	padding: 16px;
	// border: 2px solid var(--yellow);
	border-radius: 50%;
	background-color: transparent;
	cursor: pointer;
	transition:
		--fill-color 0.5s ease-in-out,
		--a 0.5s ease-in-out;
	/**/
	border: none;
	background-image:
		radial-gradient(closest-side,
			var(--fill-color) calc(100% - var(--border-width) - 1px),
			transparent calc(100% - var(--border-width)) 100%),
		conic-gradient(from 0.25turn,
			var(--border-hover-color) 0,
			var(--border-hover-color) var(--a),
			var(--border-color) var(--a),
			var(--border-color) 360deg);
	/**/
	@media(min-width:1550px) {
		width: 60px;
		height: 60px;
	}
	& svg {
		width: 12px;
		height: 12px;
		& path {
			fill: #fff;
			transition: all 0.2s ease-in-out;
		}
	}
	&:not(:disabled) {
		&:hover,
		&:focus {
			--a: 360deg;
			& svg path {
				fill: #fff;
			}
			border-color: #fff;
			background-color: transparent;
		}
		&:active {
			--border-color: var(--yellow);
			--border-hover-color: var(--yellow);
			--fill-color: var(--yellow);
			& svg path {
				fill: var(--black);
			}
			// border-color: var(--yellow);
			// background-color: var(--yellow);
		}
	}
	&--black {
		--fill-color: var(--black);
		--border-color: var(--yellow);
		--border-width: 2px;
		& svg path {
			fill: var(--yellow);
		}
	}
}
.link-svg {
	display: inline-flex;
	justify-content: center;
	path {
		transition: fill 0.2s ease-in-out;
	}
	&:hover svg path[fill="white"]:last-child {
		fill: var(--yellow);
	}
	&:active svg path[fill="white"] {
		fill: var(--yellow);
	}
}
/* --кнопки */
.input-field {
	position: relative;
	&:not(:first-child),
	& + div {
		margin-top: 34px;
	}
	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		display: block;
		width: 100%;
		height: 1px;
		background-color: #fff;
	}
	& > label {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		border: 0;
		padding: 0;
		white-space: nowrap;
		clip-path: inset(100%);
		clip: rect(0 0 0 0);
		overflow: hidden;
	}
	& input {
		border-bottom: none;
	}
	// position: relative;
	// & > label {
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 0;
	// 	transform: translate(0, -50%);
	// 	transition: all 0.2s linear;
	// }
	// & > input[value] + label,
	// & > input:focus + label,
	// & > input:active + label {
	// 	top: -0.5rem;
	// }
}
input {
	--input-color: #fff;
	--input-ph-color: rgba(255, 255, 255, 0.3);
	width: 100%;
	padding: 8px 0;
	border: none;
	border-bottom: 1px solid var(--input-color);
	color: var(--input-color);
	font-weight: 300;
	background-color: transparent;
	&:focus,
	&:active {
		outline: none;
	}
	&::placeholder {
		color: var(--input-color);
	}
	&:focus::placeholder {
		color: var(--input-ph-color) !important;
	}
	@include for_breakpoint(xl) {
		font-size: 1.125rem;
	}
	@media(min-width:1550px) {
		font-size: 1.25rem;
	}
}
textarea {
	--input-color: #fff;
	--input-ph-color: rgba(255, 255, 255, 0.3);
	width: 100%;
	padding: 8px;
	border: none;
	border-radius: 2px;
	color: var(--input-color);
	font-size: 0.75rem;
	font-weight: 300;
	background-color: var(--gray);
	resize: none;
	@include for_breakpoint(xl) {
		padding: 20px;
		font-size: 1rem;
	}
	&:focus,
	&:active {
		outline: none;
	}
	&::placeholder {
		color: var(--input-color);
		font-weight: 300;
	}
	&:focus::placeholder {
		color: var(--input-ph-color) !important;
	}
}
.btn-checkbox {
	input[type="checkbox"] {
		display: none;
	}
	.btn--sm {
		text-transform: unset;
		letter-spacing: unset;
		font-size: 14px;
	}
}