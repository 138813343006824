[data-aos]::before,
[data-aos]::after,
[data-aos]::placeholder {
    transition: all .9s ease;
}
[data-aos=text-reveal-down] {
	background-position: 100% 100%;
}
[data-aos=text-reveal-down].aos-animate {
	background-position: 0 0;
}
[data-aos=animation-opacity],
[data-aos=animation-opacity]::placeholder {
	opacity: 0;
	-webkit-transition-property: opacity;
	-moz-transition-property: opacity;
	-o-transition-property: opacity;
	transition-property: opacity
}
[data-aos=animation-opacity].aos-animate,
[data-aos=animation-opacity].aos-animate::placeholder {
	opacity: 1
}
[data-aos=animation-border]::before,
[data-aos=animation-border]::after {
    width: 0 !important;
}
[data-aos=animation-border].aos-animate::before,
[data-aos=animation-border].aos-animate::after {
    width: 100% !important;
}
[data-aos=animation-before-height] {
	border-color: transparent;
}
[data-aos=animation-before-height]::before {
    height: 0 !important;
}
[data-aos=animation-before-height].aos-animate {
	border-color: #4A4A4A;
}
[data-aos=animation-before-height].aos-animate::before {
    height: calc(100% + 2px) !important;
}
.site-body [data-aos=fade-up] {
    transform: translate3d(0, 50px, 0);
}
.of-hidden {
	overflow: hidden;
}