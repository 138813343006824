/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #b7bcc2 #fff;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}
html::-webkit-scrollbar-track {
	background: #fff;
}
*::-webkit-scrollbar-thumb {
	background-color: #b7bcc2;
	border-radius: 20px;
	border: 3px solid #fff;
}
*::-webkit-scrollbar-thumb:hover {
	background-color: #b7bcc2;
}
*::-webkit-scrollbar-corner {
	background: #b7bcc2;
}