.folio-list {
    padding-left: 0;
    margin: 0;
    list-style-type: none;

    display: flex;
    flex-direction: column;
    gap: 60px;
    @include for_breakpoint(md) {
        gap: 80px;
    }
    @include for_breakpoint(xl) {
        gap: 200px;
    }
    @media (min-width: 1550px) {
        gap: 250px;
    }
    &__item {
        &:nth-child(3n) {
            position: relative;
            &::before, &::after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                display: block;
                width: 100vw;
                height: calc(100% + 60px);
                background-color: var(--black);
                z-index: -1;
                @include for_breakpoint(md) {
                    height: calc(100% + 80px);
                }
                @include for_breakpoint(xl) {
                    height: calc(100% + 200px);
                }
                @include for_breakpoint(xxl) {
                    height: calc(100% + 250px);
                }
            }
            &::before {
                left: 50%;
            }
            &::after {
                right: 50%;
            }
        }
    }
}
.folio {
    display: flex;
    flex-direction: column;
    @include for_breakpoint(md) {
        display: grid;
        grid-template-columns: 8fr 3fr;
        grid-template-rows: repeat(3, auto);
        column-gap: 24px;
    }
    @include for_breakpoint(xl) {
        grid-template-columns: 7fr 4fr;
        grid-template-rows: auto auto 1fr;
        column-gap: 40px;
    }
    @media (min-width: 1550px) {
        grid-template-columns: 5fr 3fr;
        column-gap: 60px;
    }
    &__gallery {
        margin-bottom: 28px;
        @include for_breakpoint(md) {
            grid-area: 1 / 1 / 2 / 3;
            margin-bottom: 24px;
        }
        @include for_breakpoint(xl) {
            grid-area: 1 / 1 / 4 / 2;
            margin-bottom: 0;
        }
        .tns-nav {
            z-index: 10;
            margin-top: 3px;
            gap: 4px;
            @include for_breakpoint(md) {
                margin-top: -30px;
                gap: 20px;
            }
            button {
                position: relative;
                width: 28px;
                padding: 8px 0;
                border: none;
                background-color: transparent;
                @include for_breakpoint(md) {
                    width: 48px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 2px;
                    background-color: #fff;
                    @include for_breakpoint(md) {
                        height: 4px;
                    }
                }
                &.tns-nav-active {
                    &::before {
                        background-color: var(--yellow);
                    }
                }
            }
        }
    }
    &__gallery-list:not(.tns-slider) {
        position: relative;
        min-height: 194px;
        overflow: hidden;
        @include for_breakpoint(md) {
            min-height: 490px;
        }
        @include for_breakpoint(xl) {

        }
        @media (min-width: 1550px) {
            min-height: 620px;
        }
        .folio__gallery-item {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__gallery-list {
        padding-left: 0;
        margin: 0;
        list-style-type: none;
    }
    &__gallery-img-wrap {
    }
    &__gallery-item {
        & img {
            margin: 0 auto;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        margin: 0 0 8px;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.03em;
        @include for_breakpoint(md) {
            grid-area: 2 / 1 / 3 / 2;
            margin: 0 0 14px;
            font-size: 28px;
        }
        @include for_breakpoint(xl) {
            grid-area: 1 / 2 / 2 / 3;
            margin: -10px 0 24px;
            font-size: 32px;
        }
        @media (min-width: 1550px) {
            margin: 30px 0 30px;
            font-size: 40px;
        }
    }
    &__text {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.5;
        @include for_breakpoint(md) {
            grid-area: 3 / 1 / 4 / 2;
            margin-bottom: 0;
            font-size: 16px;
        }
        @include for_breakpoint(xl) {
            grid-area: 2 / 2 / 3 / 3;
            line-height: 1.6;
        }
        @media (min-width: 1550px) {
            margin-bottom: 20px;
            font-size: 20px;
        }
    }
    &__prop-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include for_breakpoint(md) {
            grid-area: 2 / 2 / 4 / 3;
            margin-top: 12px;
            text-align: right;
        }
        @include for_breakpoint(xl) {
            grid-area: 3 / 2 / 4 / 3;
            gap: 18px;
            margin-top: auto;
            text-align: left;
        }
        @media (min-width: 1550px) {
            gap: 24px;
            margin-bottom: 40px;
        }
    }
    &__platform {
        margin: 0;
        color: #787878;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 1.5;
        text-transform: uppercase;
        @include for_breakpoint(md) {
        }
        @include for_breakpoint(xl) {
            font-size: 16px;
        }
        @media (min-width: 1550px) {
            font-size: 18px;
        }
    }
    &__platform-name {
        text-transform: uppercase;
    }
    &__link-wrap {
        display: flex;
        align-items: center;
        gap: 15px;
        @include for_breakpoint(md) {
            margin-left: auto;
        }
        @include for_breakpoint(xl) {
            margin-left: unset;
        }
    }
    &__link-img {
        width: 20px;
        height: 20px;
        @include for_breakpoint(xl) {
            width: 24px;
            height: 24px;
        }
    }
    &__link {
        font-size: 16px;
        border-bottom: 1px solid #fff;
        transition: all 0.3s ease-in-out;
        &:hover, &:focus {
            color: var(--yellow);
            border-bottom-color: var(--yellow);
        }
        &:active {
            border-bottom-color: transparent;
        }
        @include for_breakpoint(md) {
        }
        @include for_breakpoint(xl) {
            font-size: 20px;            
        }
        @media (min-width: 1550px) {
            font-size: 22px;
        }
    }
    &-list__item:nth-child(2n) & {
        @include for_breakpoint(xl) {
            grid-template-columns: 4fr 7fr;
        }
        @media (min-width: 1550px) {
            grid-template-columns: 3fr 5fr;
        }
        &__gallery {
            @include for_breakpoint(xl) {
                grid-area: 1 / 2 / 4 / 3;
            }
        }
        &__title {
            margin: 0 0 8px;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.03em;
            @include for_breakpoint(xl) {
                grid-area: 1 / 1 / 2 / 2;
                margin: -10px 0 24px;
                font-size: 32px;
            }
            @media (min-width: 1550px) {
                margin: 30px 0 30px;
                font-size: 40px;
            }
        }
        &__text {
            margin-bottom: 20px;
            @include for_breakpoint(xl) {
                grid-area: 2 / 1 / 3 / 2;
            }
            @media (min-width: 1550px) {
            }
        }
        &__prop-wrap {
            @include for_breakpoint(xl) {
                grid-area: 3 / 1 / 4 / 2;
                margin-top: auto;
                text-align: left;
            }
        }
    }
}