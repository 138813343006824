.site-main {
	&__card-counter-list {
		@media(min-width:1550px) {
			margin-top: -14px !important;
		}
	}
	&__section-rating,
	&__section-form {
		@media(min-width:1550px) {
			margin-top: -50px !important;
		}
	}
}
.section-card {
	&__content {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		gap: calc(var(--gutter-x) * 0.5);
		@media (min-width: 710px) {
			width: 50%;
			gap: 20px;
		}
		@media(min-width:1550px) {
			gap: 20px;
		}
	}
	&__item {
		width: 50%;
	}
	@media (min-width: 710px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.card-simple {
	display: flex;
	flex-direction: column;
	align-items: start;
	height: 100%;
	padding: 20px 15px;
	line-height: 1.3;
	background-color: var(--black);
	@include for_breakpoint(lg) {
		padding: 40px 30px;
	}
	@include for_breakpoint(xl) {
		padding: 50px 30px 40px;
	}
	@media(min-width:1550px) {
		padding: 40px;
	}
	&__img {
		margin-bottom: 8px;
		@include for_breakpoint(lg) {
			width: 125px;
			height: 94px;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 20px;
		}
		@media(min-width:1550px) {
			margin-bottom: 21px;
		}
	}
	&__title {
		margin: 0;
		font-size: var(--fs-accent);
		br {
			display: none;
			@include for_breakpoint(xl) {
				display: block;
			}
		}
	}
}
.card-counter-list {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	flex-direction: column;
	@include for_breakpoint(md) {
		flex-direction: row;
	}
	@media(min-width:1550px) {
		gap: 20px;
	}
	&__item {
		border: 1px solid #4A4A4A;
		border-top: none;
		&:first-child {
			border-top: 1px solid #4A4A4A;
		}
		@include for_breakpoint(md) {
			width: 25%;
			border: 1px solid #4A4A4A;
		}
	}
	&--gap-0 {
		gap: 0;
		.card-counter-list {
			&__item {
				border: 1px solid #4A4A4A;
			}
		}
	}
	&.js-slider-counters {
		@include for_breakpoint(md-max) {
			flex-direction: row;
			.card-counter-list {
				&__item {
					// width: calc(100% / 4);
				}
			}
		}
	}
}
.card-counter {
	height: 100%;
	padding: 12px 10px 20px;
	border-bottom: none;
	@include for_breakpoint(md) {
		padding: 16px;
	}
	@include for_breakpoint(xl) {
		padding: 34px 12px 40px 20px;
	}
	@media(min-width:1550px) {
		padding: 28px 30px 44px;
	}
	&__index {
		margin: 0;
		color: var(--yellow);
		letter-spacing: 0.03rem;
		@media(min-width:1550px) {
			margin-bottom: 10px;
		}
	}
	&__number {
		font-size: 3rem;
		@include for_breakpoint(xl) {
			font-size: 5rem;
		}
		@media(min-width:1550px) {
			font-size: 6rem;
		}
	}
	&__unit {
		display: block;
		font-size: 1.25rem;
		font-weight: 400;
		margin-bottom: 2px;
		@include for_breakpoint(xl) {
			font-size: 2.25rem;
			margin-bottom: 12px;
		}
		@media(min-width:1550px) {
			margin-top: -10px;
			font-size: 2.5rem;
			font-weight: 300;
		}
	}
	&__desc {
		margin: 0;
		font-size: var(--fs);
	}
}
.card-service {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
	transition: all 0.6s ease-in-out;
	&__title i {
		transition: all .6s ease-in-out;
	}
	position: relative;
	&::before {
		content: "";
		position: absolute;
		display: block;
		width: 0;
		height: 100%;
		background-color: transparent;
		top: 50%;
		left: calc(-1 * var(--gutter-x) / 2);
		transform: translate(0, -50%);
		transition: all .6s ease-in-out;
	}
	&__btn-svg {
		width: 80px;
		height: 80px;
	}
	@include for_breakpoint(sm-max) {
		&.mobile-hover {
			color: var(--black);
			.card-service__title i {
				color: var(--black) !important;
			}
			.card-service__btn-svg {
				--border-color: var(--black);
				--fill-color: var(--yellow);
				& svg path {
					fill: var(--black);
				}
				&:hover {
					--fill-color: var(--black);
					& svg path {
						fill: #fff;
					}
				}
				&:active {
					background-color: var(--black);
					& svg path {
						fill: var(--yellow);
					}
				}
			}
			&::before {
				background-color: var(--yellow);
				width: 100vw;
			}
		}
	}
	@include for_breakpoint(md) {
		position: relative;
		flex-wrap: nowrap;
		gap: 20px;
		&:hover {
			color: var(--black);
			.card-service__title i {
				color: var(--black) !important;
			}
			.card-service__btn-svg {
				--border-color: var(--black);
				--fill-color: var(--yellow);
				& svg path {
					fill: var(--black);
				}
				&:hover {
					--fill-color: var(--black);
					& svg path {
						fill: #fff;
					}
				}
				&:active {
					background-color: var(--black);
					& svg path {
						fill: var(--yellow);
					}
				}
			}
		}
		&:hover::before {
			background-color: var(--yellow);
			width: 100vw;
		}
	}
	@include for_breakpoint(xl) {
		gap: 42px;
		padding: 52px 0 42px;
	}
	@media(min-width:1550px) {
		&::before {
			left: calc(50% - 50vw);
		}
		gap: 38px;
		padding: 78px 0 58px;
	}
	&__desc {
		position: relative;
		z-index: 1;
		width: 100%;
		margin-bottom: 18px;
		@include for_breakpoint(md) {
			order: 2;
			width: auto;
		}
	}
	&__title {
		margin: 0;
		margin-bottom: 21px;
		letter-spacing: 0.04em;
		@media(min-width:1550px) {
			margin-bottom: 60px;
		}
	}
	&__tags {
		margin: 0;
		font-size: 0.75rem;
		line-height: 1.6;
		word-spacing: 0.4rem;
		@include for_breakpoint(md) {
			word-spacing: 0.5rem;
			line-height: 1.6;
		}
		@include for_breakpoint(xl) {
			font-size: 1rem;
		}
		@media(min-width:1550px) {
			line-height: 1.8;
		}
	}
	&__btn-svg {
		position: relative;
		z-index: 1;
		@include for_breakpoint(md) {
			order: 3;
			flex-shrink: 0;
			width: 100px;
			height: 100px;
		}
		@include for_breakpoint(xl) {
			width: 160px;
			height: 160px;
			margin-left: 60px;
			& svg {
				width: 24px;
				height: 24px;
			}
		}
		@media(min-width:1550px) {
			width: 180px;
			height: 180px;
		}
	}
	&__img-wrap {
		position: relative;
		z-index: 1;
		width: 80px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #2D2D2D;
		@include for_breakpoint(md) {
			order: 1;
			flex-shrink: 0;
			width: 124px;
			height: 124px;
		}
		@include for_breakpoint(xl) {
			width: 190px;
			height: 190px;
		}
		@media(min-width:1550px) {
			width: 220px;
			height: 220px;
		}
	}
	&__img {
		max-width: 60px;
		@include for_breakpoint(md) {
			max-width: unset;
			width: 90px;
		}
		@include for_breakpoint(xl) {
			width: 100px;
		}
		@media(min-width:1550px) {
			width: 120px;
		}
	}
}
.plus-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: 12px;
	column-gap: 24px;
	padding: 0;
	margin: 0;
	list-style-type: none;
	@include for_breakpoint(md) {
		gap: 30px;
	}
	@include for_breakpoint(xl) {
		gap: 20px;
	}
	@media(min-width:1550px) {
		gap: unset;
		row-gap: 24px;
	}
	&__item {
		width: calc(50% - 15px);
		@media(min-width:1550px) {
			width: calc(50% - 24px);
		}
	}
}
.plus {
	&__img-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		padding: 8px;
		background-color: var(--black);
		@include for_breakpoint(xl) {
			width: 52px;
			height: 52px;
		}
	}
	&__img-wrap {}
	&__img {
		@include for_breakpoint(xl) {
			width: 36px;
			height: 36px;
		}
	}
	&__desc {
		margin: 0;
		margin-top: 10px;
		font-size: 0.75rem;
		line-height: 1.3;
		@include for_breakpoint(md) {
			font-size: 0.875rem;
		}
		@include for_breakpoint(xl) {
			margin-top: 24px;
			font-size: 1.125rem;
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
		}
	}
}
.section-plus {
	@include for_breakpoint(md) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(1fr, 3);
		column-gap: 24px;
	}
	&__title {
		margin-bottom: 13px;
		@include for_breakpoint(md) {
			grid-row: 1/2;
			grid-column: 1/2;
			margin: 0;
		}
	}
	&__img-wrap {
		margin-bottom: 20px;
		img {
			width: 100%;
		}
		@include for_breakpoint(md) {
			grid-row: 1/4;
			grid-column: 2/3;
			margin-bottom: 0;
		}
	}
	&__list {
		margin-bottom: 18px;
		@include for_breakpoint(md) {
			grid-row: 2/3;
			grid-column: 1/2;
		}
	}
	&__link-wrap {
		display: flex;
		align-items: end;
		@media(min-width:1550px) {
			align-items: center;
		}
	}
	&__link {
		display: inline-block;
		@include for_breakpoint(md) {
			grid-row: 3/4;
			grid-column: 1/2;
		}
		@include for_breakpoint(xl) {
			font-size: 1.5rem;
		}
		@media(min-width:1550px) {
			font-size: 1.875rem;
		}
	}
}
.section-project {
	&__title {
		br {
			display: none;
		}
		@include for_breakpoint(md) {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			margin-bottom: 30px;
			br {
				display: block;
			}
		}
		@include for_breakpoint(xl) {
			margin-bottom: 40px;
			span {
				display: block;
				width: 55%;
				margin-top: 0 !important;
				font-size: 1.125rem !important;
			}
		}
		@media(min-width:1550px) {
			margin-bottom: 60px;
			span {
				display: block;
				width: 63%;
				font-size: 1.375rem !important;
			}
		}
	}
}
.project-list {
	padding: 0;
	margin: 0;
	list-style-type: none;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 100%;
		transform: translate(-50%, 0);
		display: block;
		width: 100%;
		height: 1px;
		background-color: #4A4A4A;
	}
	&__item {
		// border-top: 1px solid #4A4A4A;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			left: 50%;
			top: 100%;
			transform: translate(-50%, 0);
			display: block;
			width: 100%;
			height: 1px;
			background-color: #4A4A4A;
		}
		&:hover {
			.card-project {
				&__subtitle,
				&__summary,
				&__summary::after {
					color: var(--yellow);
				}
			}
		}
		&.active {
			&::before {
				content: "";
				position: absolute;
				display: block;
				width: 9999px;
				height: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: var(--black);
			}
		}
	}
}
.card-project {
	&__subtitle {
		// display: none;
		display: none;
		width: 55%;
		margin-left: auto;
		color: #fff;
		font-size: 0.75rem;
		font-weight: 300;
		text-transform: none;
		transition: all 0.3s ease-in-out;
		@include for_breakpoint(md) {
			display: block;
			font-size: 1.125rem;
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
			width: 63%;
		}
	}
	&__summary {
		position: relative;
		padding: 20px 0;
		font-weight: 500;
		text-transform: uppercase;
		list-style: none;
		letter-spacing: 0.03em;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		@include for_breakpoint(md) {
			display: flex;
			align-items: center;
		}
		@include for_breakpoint(lg) {
			font-size: 1.25rem;
		}
		@include for_breakpoint(xl) {
			padding: 40px 0;
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
		}
		&::marker,
		&::-webkit-details-marker {
			display: none;
			list-style-type: none;
		}
		&::after {
			content: "+";
			position: absolute;
			top: calc(50% + 3px);
			right: 0;
			transform: translate(0, -50%);
			display: inline-block;
			font-size: 36px;
			font-weight: 300;
			transition: all 0.2s ease-in-out;
			@media(min-width:1550px) {
				font-size: 48px;
			}
		}
	}
	&__details {
		overflow: hidden;
	}
	&__details + &__content {
		max-height: 0;
		transition: max-height 0.5s ease;
	}
	&__details[open] > &__summary {
		&::after {
			transform: translate(0, -50%) rotate(45deg);
		}
	}
	&__details[open] + &__content {
		max-height: 500px;
	}
	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		// padding-bottom: 38px;
		@include for_breakpoint(md) {
			display: grid;
			grid-template-rows: minmax(80px, auto) auto;
			grid-template-columns: 1fr 56%;
			column-gap: 32px;
		}
		@include for_breakpoint(xl) {
			grid-template-columns: 1fr 55%;
		}
		@media(min-width:1550px) {
			grid-template-columns: 1fr 63%;
			column-gap: 130px;
			// padding-bottom: 66px;
		}
	}
	&__theme {
		margin-top: 0;
		margin-bottom: 30px;
		font-size: 0.75rem;
		@include for_breakpoint(md) {
			display: none;
		}
	}
	&__char-list {
		display: flex;
		flex-direction: column;
		gap: 17px;
		padding: 0;
		margin: 0;
		margin-bottom: 28px;
		list-style-type: none;
		@include for_breakpoint(md) {
			grid-row: 1/2;
			grid-column: 2/3;
		}
		@include for_breakpoint(xl) {
			margin-bottom: 40px;
			gap: 20px;
			font-size: 1.125rem;
			svg {
				width: 20px;
				height: 20px;
			}
		}
		@media(min-width:1550px) {
			gap: 24px;
			svg {
				width: 24px;
				height: 24px;
			}
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
		}
		& li {
			display: flex;
			align-items: center;
			gap: 10px;
			@include for_breakpoint(xl) {
				gap: 20px;
			}
		}
	}
	&__desc {
		margin-bottom: 30px;
		color: #D9D9D9;
		font-size: 0.75rem;
		line-height: 1.5;
		@include for_breakpoint(md) {
			grid-row: 2/3;
			grid-column: 2/3;
			// padding-bottom: 38px;
			margin-bottom: 0;
		}
		@include for_breakpoint(xl) {
			font-size: 1rem;
			margin-top: -12px;
		}
		@include for_breakpoint(xl) {
			font-size: 1rem;
		}
		@media(min-width:1550px) {
			padding-top: 24px;
			padding-right: 160px;
			padding-bottom: 0;
			font-size: 1rem;
		}
		& > p:first-child {
			margin-top: 0;
		}
		& > p:last-child {
			margin-bottom: 0;
		}
	}
	&__img-link-wrap {
		margin-bottom: 20px;
		@include for_breakpoint(md) {
			// margin-bottom: 40px;
			grid-row: 1/3;
			grid-column: 1/2;
		}
	}
	&__img-link {
		position: relative;
		display: flex;
		flex-direction: row;
		border: 1px solid #4A4A4A;
		padding-right: 52px;
		transition: border-color 0.2s ease-in-out;
		@include for_breakpoint(xl) {
			padding-right: 80px;
		}
		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	z-index: 2;
		// 	display: block;
		// 	width: calc(100% - 52px);
		// 	height: 100%;
		// 	background-color: rgba(45, 45, 45, 0.35);
		// 	@include for_breakpoint(xl) {
		// 		width: calc(100% - 80px);
		// 	}
		// }
		&:hover {
			border-color: var(--yellow);
			.card-project__img-panel {
				background-color: var(--yellow);
			}
		}
	}
	&__img {
		object-fit: cover;
		width: 100%;
		height: 194px;
		@include for_breakpoint(xl) {
			height: 300px;
		}
	}
	&__img-panel {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 52px;
		height: 100%;
		padding: 10px 0;
		transition: background-color 0.2s ease-in-out;
		@include for_breakpoint(xl) {
			width: 80px;
		}
		@media(min-width:1550px) {
			width: 80px;
			padding: 24px 0;
			svg {
				width: 18px;
				height: 18px;
				&:first-of-type {
					width: 32px;
					height: 20px;
				}
			}
		}
	}
}
.section-rating {
	@include for_breakpoint(md) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__title {
		br {
			display: none;
			@include for_breakpoint(md) {
				display: block;
			}
		}
	}
	&__list {
		@include for_breakpoint(md) {
			width: 50%;
		}
	}
}
.cart-rating-list {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	justify-content: space-between;
}
.card-rating {
	position: relative;
	text-align: center;
	&__img {
		margin: 0 auto 4px;
		width: 20px;
		height: 20px;
		@include for_breakpoint(xl) {
			position: absolute;
			top: 12px;
			left: calc(50% - 64px);
			transform: translate(-50%, 0);
			width: 32px;
			height: 32px;
		}
		@media(min-width:1550px) {
			top: 12px;
			left: calc(50% - 92px);
			transform: translate(-50%, 0);
			width: 46px;
			height: 46px;
		}
	}
	&__number {
		display: block;
		margin-bottom: 8px;
		font-size: 2.25rem;
		font-weight: 500;
		@include for_breakpoint(xl) {
			font-size: 3rem;
		}
		@media(min-width:1550px) {
			margin-bottom: 24px;
			font-size: 4rem;
		}
	}
	@include for_breakpoint(xl) {
		font-size: 1.5rem;
	}
	@media(min-width:1550px) {
		font-size: 1.875rem;
	}
}
.section-form {
	padding: 22px 0 30px;
	@include for_breakpoint(md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 30px;
	}
	@include for_breakpoint(xl) {
		padding: 60px 0;
	}
	@media(min-width:1200px) and (max-width:1549px)  {
		row-gap: 0;
	}
	@media(min-width:1550px) {
		gap: 0;
		column-gap: 90px;
		&__textarea {
			margin-top: 68px !important;
		}
	}
	&__title {
		margin-top: 0;
		@include for_breakpoint(md) {
			width: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		}
		@media(min-width:1200px) and (max-width:1549px)  {
			margin-bottom: 32px;
		}
		& span:not([class]),
		& span[class*=aos] {
			position: relative;
			left: 12px;
			margin-top: 4px;
			font-size: 0.75rem;
			@include for_breakpoint(md) {
				bottom: 3px;
				width: calc(50% - 15px);
				font-size: 1rem;
			}
			@include for_breakpoint(xl) {
				font-size: 1.125rem;
				margin-bottom: 6px;
			}
			@media(min-width:1550px) {
				width: calc(50% - 45px);
				font-size: 1.375rem;
			}
			&::before {
				content: "/";
				position: absolute;
				left: -12px;
				bottom: 3px;
				display: block;
				width: 24px;
				height: 100%;
			}
		}
	}
	&__list {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		@include for_breakpoint(md) {
			order: 1;
			width: calc(50% - 15px);
		}
		@media(min-width:1550px) {
			gap: 20px;
			width: 50%;
		}
	}
	&__item {
		width: 50%;
	}
	&__form {
		@include for_breakpoint(md) {
			width: calc(50% - 15px);
		}
		@media(min-width:1550px) {
			width: calc(50% - 90px);
		}
	}
	&__group {
		width: 100%;
		@include for_breakpoint(xl) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 30px;
			margin-top: 50px !important;
			margin-bottom: 50px;
			.input-field {
				margin-top: 0;
				width: 50%;
			}
		}
	}
	&__textarea.input-field::after {
		display: none;
	}
}
.card-staff {
	&__img-wrap {
		position: relative;
		margin-bottom: 7px;
		@include for_breakpoint(xl) {
			margin-bottom: 20px;
		}
		&::before {
			content: "";
			position: absolute;
			z-index: 2;
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(45, 45, 45, 0.35);
			transition: background-color 0.2s linear;
		}
	}
	&__img {
		width: 100%;
		object-fit: cover;
	}
	&__desc {
		display: flex;
		flex-direction: column;
		gap: 2px;
		margin: 6px 0 23px;
	}
	&__title {
		font-size: 0.875rem;
		@include for_breakpoint(xl) {
			font-size: 1.5rem;
		}
		@include for_breakpoint(xl) {
			font-size: 1.5rem;
		}
		@media(min-width:1550px) {
			font-size: 1.375rem;
		}
	}
	&__position {
		font-size: 0.75rem;
		@include for_breakpoint(xl) {
			font-size: 1rem;
		}
	}
}