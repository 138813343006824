.block-contacts {
    padding: 0;
    margin: 0;
    list-style-type: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 50px;
    width: 100%;
    @include for_breakpoint(md) {
        flex-direction: row;
        gap: 40px;
    }
    @include for_breakpoint(xl) {
    }
    @media (min-width: 1550px) {
        gap: 20px;
    }
    &__item {
        // flex: 1 0 auto;
        width: 100%;
        @include for_breakpoint(md) {
            width: calc(50% - 20px);
        }
        @media (min-width: 1550px) {
            width: calc(50% - 10px);
        }
    }
}
.card-contacts {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__contacts-list {
        margin-bottom: 40px;
        @include for_breakpoint(md) {
            margin-bottom: 52px;
        }
        @include for_breakpoint(xl) {
            margin-bottom: 70px;
        }
        @media (min-width: 1550px) {
            margin-bottom: 65px;
        }
    }
    &__title {
        margin-bottom: 28px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        @include for_breakpoint(md) {
            font-size: 20px;
        }
        @include for_breakpoint(xl) {
            margin-bottom: 40px;
            font-size: 30px;
        }
    }
    &__address {
        margin-top: auto;
        margin-bottom: 15px;
        font-size: 12px;
        @include for_breakpoint(xl) {
            font-size: 22px;
        }
        @media (min-width: 1550px) {
        }
    }
    &__map {
        width: 100% !important;
        height: 246px !important;
        @include for_breakpoint(xl) {
            height: 388px !important; 
        }
        @media (min-width: 1550px) {
            height: 418px !important;
        }
    }
}
.contacts-list {
    padding: 0;
    margin-left: 0;
    list-style-type: none;

    display: flex;
    flex-direction: column;
    gap: 22px;
    @include for_breakpoint(md) {
        gap: 24px;
    }
    @include for_breakpoint(xl) {
        gap: 26px;
    }
    &__item {
        width: 100%;
        display: flex;
        align-items: start;
        gap: 10px;
        font-size: 14px;
        @include for_breakpoint(md) {
            gap: 10px;
        }
        @include for_breakpoint(xl) {
            gap: 6px;
            font-size: 22px;
        }
    }
    &__icon {
        width: 18px;
        @include for_breakpoint(xl) {
            margin-right: 10px;
            width: 24px;
        }
    }
    &__dt {
        margin: 0;
    }
    &__hr {
        position: relative;
        top: 11px;
        flex: 1 0 auto;
        border-bottom: 1px solid #4A4A4A;
        @include for_breakpoint(md) {
        }
        @include for_breakpoint(xl) {
            top: 17px;
        }
    }
    &__dd {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin: 0;
        width: 50%;
        @include for_breakpoint(md) {
            width: 55%;
        }
        @include for_breakpoint(xl) {
            width: 50%;
        }
        @media (min-width: 1550px) {
            width: 63%;
        }
        br {
            display: none;
        }
        a:not([href^="tel"]) {
            color: var(--yellow);
            &:hover, &:focus {
                color: #fff;
            }
        }
        a {
            align-self: start;
            border-bottom: 1px solid transparent;
            &:active {
                color: #898989 !important;
                border-bottom-color: #898989 !important;
            }
        }
    }
}
.ymaps3x0--marker-layer {
    width: 100% !important;
    height: 100% !important;
}
.icon-marker {
    position: relative;
    width: 28px;
    height: 39px;
    left: 50%;
    transform: translate(-100%, -100%);
}