.cost {
    position: relative;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    // background-color: var(--black);
    background-image: linear-gradient(
        90deg,
        var(--yellow) 0,
        var(--yellow) 50%,
        var(--black) 50%,
        var(--black) 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 100%;
    transition: background-position 0.6s ease-in-out,
                color 0.6s ease-in-out;
    @include for_breakpoint(md) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 45px;
        width: 100%;
        padding: 15px 20px;
    }
    @include for_breakpoint(xl) {
        gap: 30px;
        padding: 38px 30px;
    }
    @media(min-width:1550px) {
        gap: 80px;
        padding: 30px 30px 30px 48px;
    }
    &__title {
        margin: 0;
        margin-bottom: 14px;
        color: var(--yellow);
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        transition: color 0.6s ease-in-out;
        @include for_breakpoint(md) {
            flex-shrink: 0;
            width: 26%;
            margin-bottom: 0;
        }
        @include for_breakpoint(xl) {
            width: 22%;
            font-size: 1.25rem;
        }
        @media(min-width:1550px) {
            width: 18%;
            font-size: 1.375rem;
        }
    }
    &__desc {
        margin: 0;
        margin-bottom: 32px;
        color: #898989;
        font-size: 0.75rem;
        line-height: 1.4em;
        transition: color 0.6s ease-in-out;
        @include for_breakpoint(md) {
            // width: 30%;
            // flex-shrink: 0;
            margin-bottom: 0;
        }
        @include for_breakpoint(lg) {
            // width: 40%;
            font-size: 1rem;
        }
        @media(min-width:1550px) {
            font-size: 1.375rem;
        }
    }
    &__footer {
        @include for_breakpoint(md) {
            flex-shrink: 0;
            width: 30%;
            max-width: 220px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // gap: 45px;
        }
        @include for_breakpoint(lg) {
            // width: 25%;
        }
        @include for_breakpoint(xl) {
            width: 40%;
            max-width: 390px;
            gap: 25px;
        }
        @media(min-width: 1320px) {
            width: 42%;
            max-width: 420px;
            gap: 45px;
        }
        @media(min-width:1550px) {
            max-width: 520px;
            gap: 85px;
        }
    }
    &__rate {
        font-size: 1rem;
        @include for_breakpoint(md) {
            
        }
        @include for_breakpoint(xl) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.25rem;
        }
        @media(min-width: 1320px) {
        }
        @media(min-width:1550px) {
            font-size: 1.375rem;
        }
    }
    &__price {
        margin: 0;
        margin-bottom: 8px;
        @include for_breakpoint(xl) {
            margin-bottom: 0;
        }
    }
    &__value {
        margin: 0;
    }
    &__btn-wrap {
        position: absolute;
        bottom: 20px;
        right: 15px;
        @include for_breakpoint(md) {
            position: static;
            display: flex;
            align-items: center;
        }
    }
    &__btn {
        width: 20px;
        height: 20px;
        @include for_breakpoint(xl) {
            width: 24px;
            height: 24px;
        }
        & path {
            transition: stroke 0.3s ease-in-out 0.3s,
                        fill 0.3s ease-in-out 0.3s;
        }
    }
    &:hover {
        // background-color: var(--yellow);
        background-position: 0 100%;
        color: var(--black);
        .cost {
            &__title,
            &__desc {
                color: var(--black);
            }
            &__btn path {
                // stroke: var(--yellow);
                fill: var(--black);
            }
        }
    }
}