@font-face {
    font-display: swap;
    font-family: 'Stem';
    src: url('Stem-Thin.eot');
    src: local('Stem Thin'), local('Stem-Thin'),
        url('../fonts/Stem-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Stem';
    src: url('Stem-ExtraLight.eot');
    src: local('Stem Extra Light'), local('Stem-ExtraLight'),
    url('../fonts/Stem-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Stem';
    src: url('Stem-SemiLight.eot');
    src: local('Stem Semi Light'), local('Stem-SemiLight'),
        url('../fonts/Stem-SemiLight.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
// @font-face {
//     font-display: swap;
//     font-family: 'Stem';
//     src: url('Stem-Light.eot');
//     src: local('Stem Light'), local('Stem-Light'),
//         url('../fonts/Stem-Light.woff2') format('woff2');
//     font-weight: 300;
//     font-style: normal;
// }
@font-face {
    font-display: swap;
    font-family: 'Stem';
    src: url('Stem-Regular.eot');
    src: local('Stem Regular'), local('Stem-Regular'),
        url('../fonts/Stem-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Stem';
    src: url('Stem-Medium.eot');
    src: local('Stem Medium'), local('Stem-Medium'),
        url('../fonts/Stem-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Stem';
    src: url('Stem-Bold.eot');
    src: local('Stem Bold'), local('Stem-Bold'),
        url('../fonts/Stem-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}